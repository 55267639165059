window.o_global.eventQBus.on("assets.formular.counter", (selector = "") => {
  const counterHosts = document.querySelectorAll(
    `${selector} .js-pl_counter[data-minlength], ${selector} .js-pl_counter[maxlength]`
  );

  const textareaElementTagName = "TEXTAREA";
  const inputElementTagName = "INPUT";

  const setCounterInnerText = ({ counter, maxLength, minLength, hostElement }) => {
    const { value } = hostElement;
    if (minLength > 0) {
      counter.innerText = `${value.length} (min. ${minLength})`;
    } else if (maxLength > 0) {
      counter.innerText = `${value.length} (max. ${maxLength})`;
    }
  };

  const insertCounter = ({ maxLength, minLength, hostElement }) => {
    const counter = document.createElement("small");
    counter.classList.add("pl_counter");

    setCounterInnerText({ counter, maxLength, minLength, hostElement });

    hostElement.parentNode.appendChild(counter);

    return counter;
  };

  const eventMap = {
    input: ({ counter, maxLength, minLength, hostElement }) => {
      const { value, parentNode } = hostElement;

      setCounterInnerText({ counter, maxLength, minLength, hostElement });

      if (minLength >= 0 && value.length >= minLength) {
        const invalidClassName = getInvalidClassName(hostElement);
        if (invalidClassName) {
          parentNode.classList.remove(invalidClassName);
        }
      }
    },
    blur: ({ minLength, hostElement }) => {
      const { value, parentNode } = hostElement;
      if (minLength >= 0 && value.length < minLength) {
        const invalidClassName = getInvalidClassName(hostElement);
        if (invalidClassName) {
          parentNode.classList.add(invalidClassName);
        }
      }
    },
  };

  const getInvalidClassName = (element) => {
    if (element.tagName === inputElementTagName) {
      return "pl_input--invalid";
    }
    if (element.tagName === textareaElementTagName) {
      return "pl_textarea--invalid";
    }
  };

  [].forEach.call(counterHosts, (hostElement) => {
    const { maxLength } = hostElement;
    const minLength = parseInt(hostElement.getAttribute("data-minlength"), 10) || -1;

    if (!hostElement || (maxLength <= 0 && minLength <= 0)) {
      return;
    }

    const counter = insertCounter({ maxLength, minLength, hostElement });

    Object.keys(eventMap).forEach((eventName) => {
      hostElement.addEventListener(eventName, ({ type }) =>
        eventMap[type]({
          counter,
          maxLength,
          minLength,
          hostElement,
        })
      );
    });
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.formular.counter", "body");
});
