import { SheetCloseTypes } from "./sheetEvents";

export type SheetSettings = DefaultSheetSettings & OptionalSheetSettings;

export type SheetCloseCallback = (params: { closeType: SheetCloseTypes }) => void;

export type SheetElementContent = string | HTMLElement | HTMLElement[];

export type DefaultSheetSettings = {
  content: string;
  title: string;
  headerContent: string;
  menuContent: string;
  menuBarVertical: boolean;
  showBackButton: boolean;
  showCloseButton: boolean;
  lockMobileHeight: boolean;
  createTrackingContext: boolean;
};

export type OptionalSheetSettings = Partial<{
  /*                                        */
  id: string;
  /*                         */
  url: string;
  /*                                     */
  openCallback: () => void;
  /*                                      */
  closeCallback: SheetCloseCallback;
  /*                                       */
  preOpenCallback: () => void;
  /*                                        */
  preCloseCallback: SheetCloseCallback;
  /*                                  */
  trackingObject: Record<string, Array<string>>;
  /*                                                         */
  initialMobileHeight: string | null;
  /*                                                                  */
  fullBleedContent: boolean;
  /*                                                                                                               */
  contentGreyBackground: boolean;
  /*                                                                                                                           */
  convertLayerLinks: boolean;
}>;

export const browserTransforms = ["webkitTransform", "msTransform", "transform"] as const;

export interface SheetAPI {
  close: () => void;
  open: () => void;

  back: () => void;
  getContainer: () => HTMLElement;

  getSettings: () => Record<string, unknown>;
  switchSheetContent: (settings: Record<string, unknown>) => void;

  setBackButton: (callback: EventListener, title: string) => void;
  getBackButton: () => HTMLElement | undefined;
  removeBackButton: () => void;
  setCloseButton: () => void;
  getCloseButton: () => HTMLElement | undefined;
  removeCloseButton: () => void;
  setHeader: (header: SheetElementContent) => void;
  getHeader: () => HTMLElement;
  setTitle: (title: string) => void;
  getTitle: () => HTMLElement;
  setContent: (content: SheetElementContent) => void;
  getContent: () => HTMLElement;
  setMenuBar: (menuBar: SheetElementContent) => void;
  getMenuBar: () => HTMLElement;
  hideMenuBar: () => void;
}
