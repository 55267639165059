export interface AnalyzeTargetReturn {
  autoTab: boolean;
  currentLength: number;
  inputType: string;
  maxLength: number;
  intOnly: boolean;
  value: string;
}

/**
 *
 *
 *
 *
 */
const checkValue = (value: string | number, intOnly: boolean): string | null => {
  if (!value) {
    return null;
  }

  const valueAsString = value.toString();
  /*                                                          */
  /*                                                 */
  const absoluteValue = Math.abs(intOnly ? parseInt(valueAsString, 10) : parseFloat(valueAsString));

  if (!(absoluteValue >= 0 && absoluteValue < Infinity)) {
    return null;
  }

  /*                                                             */
  /*                                                         */
  const validValue = absoluteValue.toString();

  /*                                                     */
  return validValue.length < valueAsString.length ? `0${validValue}` : validValue;
};

/**
 *
 *
 *
 */
const analyzeTarget = (target: HTMLInputElement): AnalyzeTargetReturn => {
  const autoTab = (target.parentNode as HTMLElement).hasAttribute("data-autotab");
  const intOnly = (target.parentNode as HTMLInputElement).hasAttribute("data-int");
  const currentLength = target.value.length;
  const inputType = target.type;
  const maxLength = parseInt(target.getAttribute("maxlength") as string, 10);

  return {
    autoTab,
    currentLength,
    inputType,
    maxLength,
    intOnly,
    value: target.value,
  };
};

const jumpToPrevious = ({
  key,
  autoTab,
  currentLength,
}: {
  key: string;
  autoTab: boolean;
  currentLength: number;
}): boolean => {
  return (key === "Delete" || key === "Backspace") && autoTab && currentLength <= 0;
};

const preventDecimals = (input: string, intOnly: boolean): boolean => {
  return [".", ","].indexOf(input) !== -1 && intOnly;
};

const addLeadingZero = ({
  maxLength,
  resultValue,
  length,
}: {
  maxLength: number;
  resultValue: string | null;
  length: number;
}): boolean => {
  if (resultValue) {
    return (
      maxLength === 2 &&
      length === 1 &&
      !!resultValue &&
      parseInt(resultValue, 10) > 0 &&
      parseInt(resultValue, 10) <= 9
    );
  }
  return false;
};

export { checkValue, analyzeTarget, jumpToPrevious, preventDecimals, addLeadingZero };
