/**
 *
 *
 *
 */

import { events } from "@otto-ec/global-resources/event-q-bus";
import { eventLoader } from "@otto-ec/global-resources/event-loader";
import { getBodyElement } from "@otto-ec/global-resources/dom";
import { FocussedDialogEvents } from "./focussed-dialog.types";

/*                                                 */
const eventQBus = events<FocussedDialogEvents>();

/*                                               */
const jsPrefix = "js-";

/*                                                      */
const patternName = "focussed-dialog";

const attributes = {
  prev: `data-${patternName}-prev-url`,
  close: `data-${patternName}-close-url`,
  addParams: `data-${patternName}-add-params`,
} as const;

export type FocussedDialogAttributes = typeof attributes;

const classes = {
  dialog: `${jsPrefix}pl_${patternName}`,
  addClose: `${jsPrefix}pl_${patternName}__add-closeUrl`,
  addPrev: `${jsPrefix}pl_${patternName}__add-prevUrl`,
  closeLink: `${jsPrefix}pl_${patternName}__closeUrl`,
  prevLink: `${jsPrefix}pl_${patternName}__prevUrl`,
  inApp: `pl_${patternName}--inApp`,
  hideInApp: `${jsPrefix}pl_${patternName}--hideInApp`,
} as const;

export type FocussedDialogClasses = typeof classes;

const closeQueryName = "focussedDialogCloseUrl";
const prevQueryName = "focussedDialogPrevUrl";

/**
 *
 */
type UrlsQueryName = typeof closeQueryName | typeof prevQueryName;

/**
 *
 *
 *
 */
const dataAttrToQueryNameConfig = [
  /*                         */
  [attributes.prev, prevQueryName],
  /*                          */
  [attributes.close, closeQueryName],
] as const;

/**
 *
 */
const jsSelectorsToUpdateConfig = [
  `.${classes.addPrev}`,
  `.${classes.addClose}`,
  `.${classes.prevLink}`,
  `.${classes.closeLink}`,
] as [
  addPrev: `.${FocussedDialogClasses["addPrev"]}`,
  addClose: `.${FocussedDialogClasses["addClose"]}`,
  prevLink: `.${FocussedDialogClasses["prevLink"]}`,
  closeLink: `.${FocussedDialogClasses["closeLink"]}`,
];

/**
 *
 *
 *
 *
 *
 *
 *
 */
function getUrlObject(url: string): HTMLAnchorElement {
  /*                                                         */
  const a = document.createElement("a");
  a.href = url;

  /*                                                */
  /*                                      */
  a.href = a.href;
  return a;
}

/**
 *
 *
 *
 *
 */
function getURLwithExtendedSearch({
  pathname,
  search,
  hash,
  extraParams = "",
}: {
  pathname: string;
  search: string;
  hash: string;
  extraParams?: string;
}): string {
  const extended = `${search ? `${search}&` : "?"}${extraParams}`;

  const newUrl = `${pathname.indexOf("/") !== 0 ? "/" : ""}${pathname}${extended}${hash}`;
  return newUrl;
}

/**
 *
 *
 *
 *
 *
 *
 */
function appendQueryToElement(
  element: Element,
  safeUrlEncoded: string,
  queryName: UrlsQueryName,
): void {
  const tagName = element.tagName.toLowerCase();
  const ub64eAttribute = "data-ub64e";
  const ub64eString = element.getAttribute(ub64eAttribute);

  const { pathname, search, hash } = getUrlObject(
    (element.getAttribute("href") ||
      (ub64eString && window.atob(ub64eString)) ||
      element.getAttribute("action")) ??
      "",
  );

  const extraParams = `${queryName}=${safeUrlEncoded}`;
  const newUrl = getURLwithExtendedSearch({ pathname, search, hash, extraParams });

  if (tagName === "a") {
    element.setAttribute("href", newUrl);
    element.removeAttribute("disabled");
  }

  if (element.getAttribute(ub64eAttribute)) {
    element.setAttribute(ub64eAttribute, window.btoa(newUrl));
  }

  if (tagName === "form") {
    element.setAttribute("action", newUrl);
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
function isSafeUrl(url: string): boolean {
  const { host, protocol, port, pathname } = getUrlObject(url);
  let fixedHost = host;
  /**
 *
 */
  if (port === "443" || port === "80") {
    const portInHostnamePos = host.indexOf(":");
    fixedHost = host.slice(0, portInHostnamePos);
  }

  /*                                                                             */
  if (/\/{2,}/g.test(pathname)) {
    /*                                                                                 */
    return false;
  }

  const isSafe = fixedHost === window.location.host && protocol === window.location.protocol;
  /*                                   */
  return isSafe;
}

/*                                          */
/*                      */
/*                                                */
function getValueFromQueryString(queryString: string, parameterName: string): string | undefined {
  const vars = queryString.split("&");
  let res: string | undefined;

  vars.forEach((v) => {
    const equalPosition = v.indexOf("=");
    const value = v.slice(equalPosition + 1);
    const key = v.replace(`=${value}`, "");
    if (key === parameterName) {
      res = value;
    }
  });

  return res;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
function getSafeUrl(
  windowLocationSearch: string,
  queryName: UrlsQueryName,
): [decoded: string, encoded: string] | undefined[] {
  const urlString = getValueFromQueryString(windowLocationSearch.substring(1), queryName);

  if (!urlString) {
    return [];
  }

  const decoded = decodeURIComponent(urlString);
  return isSafeUrl(decoded) ? [decoded, urlString] : [];
}

/**
 *
 *
 *
 *
 */
function cleanupElement(element: Element): void {
  [classes.addPrev, classes.addClose, classes.prevLink, classes.closeLink].forEach((c) =>
    element.classList.remove(c),
  );

  [attributes.prev, attributes.close].forEach((a) => element.removeAttribute(a));
}

/**
 *
 *
 */
function cleanupElements(...elements: NodeListOf<Element>[][]): void {
  elements.flat(1).forEach((l) => l.forEach(cleanupElement));
}

/**
 *
 *
 *
 *
 *
 *
 */
function initFocusedDialog(
  windowLocationSearch: string | undefined,
  windowLocationHref: string | undefined,
  target: HTMLElement = getBodyElement(),
): void {
  /*                                                            */
  /*                         */
  if (window.o_util.cookie.exists("app")) {
    target.querySelectorAll(`.${classes.dialog}`).forEach((dialog) => {
      const elementsToHide = dialog.querySelectorAll<HTMLElement>(`.${classes.hideInApp}`);

      dialog.classList.add(classes.inApp);

      elementsToHide.forEach((el) => {
        el.style.display = "none";
      });
    });
  }

  /*                                                            */
  /*                                             */
  /*                                                              */
  /*             */
  const dataAttrElements = dataAttrToQueryNameConfig.map(([attrName]) =>
    target.querySelectorAll(`[${attrName}]`),
  ) as [addPrev: NodeListOf<Element>, addClose: NodeListOf<Element>];

  const jsClassElements = jsSelectorsToUpdateConfig.map((s) => target.querySelectorAll(s)) as [
    addPrev: NodeListOf<Element>,
    addClose: NodeListOf<Element>,
    prevLink: NodeListOf<Element>,
    closeLink: NodeListOf<Element>,
  ];

  /*                                                      */
  (
    [
      [dataAttrElements[0], ...dataAttrToQueryNameConfig[0]],
      [dataAttrElements[1], ...dataAttrToQueryNameConfig[1]],
    ] as const
  ).forEach(([addElements, attrName, queryName]) => {
    addElements.forEach((el) => {
      const url = (el.getAttribute(attrName) || windowLocationHref) ?? "";

      const { pathname, search, hash } = getUrlObject(url);
      const relativeUrl = `${pathname.indexOf("/") !== 0 ? "/" : ""}${pathname}${search}${hash}`;

      /*                                        */
      appendQueryToElement(el, encodeURIComponent(relativeUrl), queryName);
    });
  });

  /*                                                   */
  if (!windowLocationSearch) {
    /*                                          */
    cleanupElements(dataAttrElements, jsClassElements);
    return;
  }

  /*                                                      */
  (
    [
      [jsClassElements[0], jsClassElements[2], prevQueryName],
      [jsClassElements[1], jsClassElements[3], closeQueryName],
    ] as const
  ).forEach(([addElements, prevOrCloseElements, queryName]) => {
    /*                                  */
    const [safeUrlDecoded, safeUrlEncoded] = getSafeUrl(windowLocationSearch, queryName);

    if (safeUrlDecoded && safeUrlEncoded) {
      /*                                                                           */
      addElements.forEach((el) => {
        appendQueryToElement(el, safeUrlEncoded, queryName);
      });

      /*                                                    */
      prevOrCloseElements.forEach((el) => {
        let url = safeUrlDecoded;

        const extraParams = el.getAttribute(attributes.addParams);
        if (extraParams) {
          const { pathname, search, hash } = getUrlObject(url);
          url = getURLwithExtendedSearch({ pathname, search, hash, extraParams });
        }

        el.setAttribute("href", url);
        el.removeAttribute("disabled");
      });
    }
  });

  /*                                          */
  cleanupElements(dataAttrElements, jsClassElements);
}

eventQBus.on("assets.focusseddialog.init", initFocusedDialog);
eventLoader.onReady(10, () => {
  initFocusedDialog(window.location.search, window.location.href);
});
