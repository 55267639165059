export const SNACKBAR_EVENTS = {
  TRIGGER: "assets.snackbar.trigger",
  OPENED: "assets.snackbar.opened",
  CLOSED: "assets.snackbar.closed",
} as const;

export type SnackbarSettings = {
  text: string;
  id?: string;
  hostContainerSelector?: string;
  containerClass?: string;
  actionLinkText?: string;
  actionFunction?: () => void;
  openCallback?: () => void;
  closeCallback?: () => void;
  trackingObject?: Record<string, string[]>;
};

export type SnackbarEvents = {
  "assets.snackbar.trigger": [settings: SnackbarSettings];
  "assets.snackbar.opened": [{ id: string }];
  "assets.snackbar.closed": [{ id: string }];
};
