const CLEAR_WRAPPER_CLASS = "p_form__input-clear-wrapper";
const CLEAR_BUTTON_CLASS = "p_form__input-clear-button";
const FORM_GROUP_CONTENT_CLASS = "p_formGroup__content";
const CLEAR_BUTTON_HIDE_CLASS = "p_form__input-clear-button--hide";
const delegationSelector =
  'input[type="text"][data-show-clear-button="true"],input:not([type])[data-show-clear-button="true"]';

function _onClickClear(e) {
  const clearButton = window.o_util.dom.getParentByClassName(e.target, CLEAR_BUTTON_CLASS);
  const inputElement = clearButton.parentNode.lastChild;

  if (inputElement.value.length > 0) {
    inputElement.value = "";
    clearButton.classList.add(CLEAR_BUTTON_HIDE_CLASS);
    inputElement.focus();
  }

  e.preventDefault();
}

function _createWrapper(hasPaliClass) {
  const icon = document.createElement("span");
  const clearButton = document.createElement("button");
  const clearButtonWrapper = document.createElement("div");

  clearButton.classList.add(CLEAR_BUTTON_CLASS, CLEAR_BUTTON_HIDE_CLASS);
  clearButton.setAttribute("type", "button");
  clearButton.title = "Eingabe löschen";
  clearButtonWrapper.classList.add(CLEAR_WRAPPER_CLASS);

  if (hasPaliClass) {
    clearButtonWrapper.classList.add(FORM_GROUP_CONTENT_CLASS);
  }

  icon.classList.add("p_iconFont");
  icon.innerHTML = "X";

  clearButton.appendChild(icon);
  clearButtonWrapper.appendChild(clearButton);
  clearButton.addEventListener("click", _onClickClear);

  return clearButtonWrapper;
}

function _hideClearButton(inputElement) {
  const clearButton = inputElement.parentNode.firstChild;

  clearButton.classList.add(CLEAR_BUTTON_HIDE_CLASS);
}

function _showHideButton(inputElement) {
  const clearButton = inputElement.parentNode.firstChild;

  clearButton.classList.remove(CLEAR_BUTTON_HIDE_CLASS);
}

function _onKeyUp(e) {
  const inputElement = e.target;
  const code = e.keyCode || e.which;
  /*                                          */
  if (
    inputElement.value.length === 0 &&
    (code === 8 || code === 46 || e.type === "cut" || e.type === "paste")
  ) {
    _hideClearButton(inputElement);
  } else if (inputElement.value.length > 0) {
    _showHideButton(inputElement);
  }
}

function _onFocus(e) {
  const inputElement = e.target;
  let paliClassName;

  if (!inputElement.matches(delegationSelector)) {
    /*                                                    */
    return;
  }

  e.preventDefault();

  const clearButtonWrapper = _createWrapper(
    inputElement.classList.contains(FORM_GROUP_CONTENT_CLASS)
  );

  inputElement.classList.remove(paliClassName);
  inputElement.parentNode.appendChild(clearButtonWrapper);
  clearButtonWrapper.appendChild(inputElement);

  inputElement.setAttribute("data-show-clear-button", "done");

  inputElement.addEventListener("keyup", _onKeyUp);
  inputElement.addEventListener("cut", function (e) {
    window.setTimeout(function () {
      _onKeyUp(e);
    }, 1);
  });

  inputElement.addEventListener("paste", function (e) {
    window.setTimeout(function () {
      _onKeyUp(e);
    }, 1);
  });

  inputElement.focus();
}

function init() {
  /*                                                                                                                                                                                                                       */
  const autoCompleteElements = document.querySelectorAll(
    'input[type="text"][autofocus][data-show-clear-button="true"],input:not([type])[data-show-clear-button="true"][autofocus]'
  );

  [].forEach.call(autoCompleteElements, function (element) {
    _onFocus({ target: element, preventDefault() {} });
  });

  /*              */
  window.o_util.event.delegate(document, "focus", delegationSelector, _onFocus, true);
}

window.o_global.eventLoader.onReady(10, init);
