window.o_global.pali.initAccordion =
  /*                          */
  window.o_global.pali.initAccordion ||
  (function () {
    /**
 *
 */
    function initAccordion() {
      window.o_util.event.delegate(document, "click", ".p_accordion__header", function () {
        let isOpen;
        let event;

        this.classList.toggle("p_accordion__header--open");

        isOpen = !!this.classList.contains("p_accordion__header--open");
        event = new CustomEvent("accordionChange", {
          detail: {
            isOpen,
          },
          bubbles: true,
        });

        this.dispatchEvent(event);
      });
    }

    window.o_global.eventLoader.onReady(10, initAccordion);
  })();
