window.o_global.pali.plAccordion = {
  toggleContent(target) {
    target.classList.toggle("pl_accordion__header--open");

    if (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1 &&
      target.parentElement
    ) {
      if (target.classList.contains("pl_accordion__header--open")) {
        target.parentElement.style.overflow = "auto";
      } else {
        target.parentElement.style.overflow = "hidden";
      }
    }
  },
  clickHandler(event: Event) {
    window.o_global.pali.plAccordion.toggleContent(event.currentTarget as HTMLElement);
    window.o_global.eventQBus.emit("assets.accordion.toggled", event);
  },
};

window.o_global.eventQBus.on("assets.accordion.init", (selector = "") => {
  document.querySelectorAll(` ${selector} .pl_accordion__header`).forEach((header) => {
    header.addEventListener("click", window.o_global.pali.plAccordion.clickHandler);
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.accordion.init", "body");
});
