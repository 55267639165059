import { submitEvent } from "@otto-ec/tracking-bct";

/**
 *
 */
window.o_global.pali.scrollTopButton = (function (w) {
  let buttonElement; /*                  */
  let isVisibleState = false; /*                      */
  let isScrolling = false; /*                                            */
  let currentScrollY; /*                    */
  let lastScrollY; /*                 */
  const windowHeight =
    window.innerHeight ||
    document.documentElement?.clientHeight ||
    document.body?.clientHeight ||
    0; /*                     */
  let scrollTimeout; /*                        */
  let hideTimeout; /*                  */
  let relativeToElement; /*                                                 */

  /**
 *
 *
 *
 */
  function hideButton() {
    if (!buttonElement || !isVisibleState) {
      return false;
    }

    buttonElement.classList.add("p_scrollTopButton--hidden");

    /*                  */
    isVisibleState = false;

    return true;
  }

  /**
 *
 *
 *
 */
  function isVisible() {
    return isVisibleState;
  }

  /**
 *
 *
 *
 *
 *
 */
  function _shouldShowButton() {
    /*                                    */
    /*                                             */
    return !isVisible();
  }

  /**
 *
 *
 *
 */
  function _calculatePosition() {
    let offset;
    let relativeToElementWidth;

    /*           */
    if (!buttonElement) {
      return;
    }

    relativeToElementWidth = relativeToElement.clientWidth;

    switch (window.o_global.device.breakpoint.getCurrentBreakpoint()) {
      case "s":
        offset = relativeToElementWidth / 2 - buttonElement.clientWidth / 2;
        break;
      case "m":
        offset = 16;
        break;
      case "l":
      case "xl":
        offset = 24;

        /*                                  */
        if (window.matchMedia("(min-width: 77em)").matches) {
          offset = (offset + buttonElement.clientWidth) * -1;
        }

        break;
      default:
        offset = 0;
    }

    /*                  */
    buttonElement.style.left = `${
      relativeToElement.offsetLeft + relativeToElementWidth - buttonElement.clientWidth - offset
    }px`;
  }

  /**
 *
 *
 *
 *
 */
  function _setRelativeToElement() {
    relativeToElement = document.body.getElementsByClassName("gridContainer")[0] || document.body;
  }

  /**
 *
 *
 *
 */
  function _buildButton() {
    /*                                                                                                   */
    buttonElement = document.createElement("button");
    buttonElement.innerHTML = "Nach oben<i>&nbsp;^</i>";
    buttonElement.classList.add("p_btn50--2nd", "p_scrollTopButton", "p_scrollTopButton--hidden");
    document.body.appendChild(buttonElement);

    buttonElement.addEventListener("click", function () {
      window.o_util.animation.scrollTo(0, 500);

      /*                                                                   */
      if (window.o_util.misc.isPreview(w.location.href)) {
        return;
      }

      submitEvent({
        ot_ToTopButton: "clicked",
      });
    });

    _setRelativeToElement();

    window.addEventListener("resize", _calculatePosition);

    _calculatePosition();
  }

  /**
 *
 *
 *
 */
  function showButton() {
    /*                             */
    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }

    hideTimeout = setTimeout(hideButton, 4000);

    _calculatePosition();

    if (!_shouldShowButton()) {
      return false;
    }

    /*                               */
    if (!buttonElement) {
      _buildButton();

      if (window.o_util.misc.isPreview(w.location.href)) {
        return false;
      }

      submitEvent({
        ot_ToTopButton: "shown",
      });
    }

    /*                                */
    setTimeout(function () {
      buttonElement.classList.remove("p_scrollTopButton--hidden");
    }, 0);

    /*                  */
    isVisibleState = true;

    return true;
  }

  /**
 *
 *
 *
 */
  function _onScroll() {
    currentScrollY =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);

    /*                                   */
    if (Math.abs(currentScrollY - lastScrollY) > 5) {
      /*                                                 */
      if (lastScrollY > currentScrollY && windowHeight * 2 < currentScrollY) {
        /*         */
        showButton();
      } else {
        /*           */
        hideButton();
      }
    }

    /*                           */
    lastScrollY = currentScrollY;
    isScrolling = false;
  }

  /**
 *
 *
 *
 */
  function _prepareScroll() {
    if (!isScrolling) {
      isScrolling = true;

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      scrollTimeout = setTimeout(_onScroll, 75);
    }
  }

  /**
 *
 *
 *
 */
  function _init() {
    window.addEventListener("touchstart", function () {
      setTimeout(_onScroll, 50);
    });
    window.addEventListener("scroll", _prepareScroll);
  }

  /*                                             */
  setTimeout(_init, 100);

  /*         */
  return {
    showButton,
    hideButton,
    isVisible,
  };
})(window);
