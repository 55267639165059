export const TOOLTIP_EVENTS = {
  INIT: "assets.tooltip.init",
  INITIALISED: "assets.tooltip.initialised",
  OPENED: "assets.tooltip.opened",
  CLOSE: "assets.tooltip.close",
  CLOSED: "assets.tooltip.closed",
} as const;

export type TooltipEvents = {
  "assets.tooltip.init": [selector?: string];
  "assets.tooltip.initialised": [];
  "assets.tooltip.opened": [{ id: string }];
  "assets.tooltip.close": [{ id: string }];
  "assets.tooltip.closed": [{ id: string }];
};
