/**
 *
 */
function initClickListeners() {
  window.o_util.event.delegate(document, "click", ".js_textExpander100__link", function (event) {
    const target = window.o_util.event.getTarget(event);
    const textExpander = window.o_util.dom.getParentByClassName(target, "p_textExpander100");
    let customEvent;
    let isOpen;

    if (textExpander) {
      textExpander.classList.toggle("p_expander--decrease");
      isOpen = !!textExpander.classList.contains("p_expander--decrease");
      customEvent = new CustomEvent("textExpanderChange", {
        detail: {
          isOpen,
        },
        bubbles: true,
      });

      this.dispatchEvent(customEvent);
    }
  });
}

window.o_global.eventLoader.onReady(10, initClickListeners);
