import { submitEvent } from "@otto-ec/tracking-bct";
import { SnackbarEvents, SnackbarSettings, SNACKBAR_EVENTS } from "./snackbarEvents";

export class Snackbar {
  settings: SnackbarSettings;

  container: HTMLDivElement;

  textContent: HTMLDivElement;

  closeIcon: SVGSVGElement;

  actionLink?: HTMLAnchorElement;

  #activeSnackbarTimerId: number | null;

  #remaining: number;

  #start!: number;

  constructor(settings: SnackbarSettings) {
    this.settings = settings;
    this.#activeSnackbarTimerId = null;
    this.#remaining = 5000;

    const markup = this.buildMarkup();

    this.container = markup.container;
    this.textContent = markup.textContent;
    this.actionLink = markup.actionLink;
    this.closeIcon = markup.closeIcon;
  }

  buildMarkup(): {
    container: HTMLDivElement;
    textContent: HTMLDivElement;
    actionLink: HTMLAnchorElement | undefined;
    closeIcon: SVGSVGElement;
  } {
    const container = document.createElement("div");
    const textContent = document.createElement("div");
    let actionLink;
    const closeIcon = document.createElementNS("http:/*                           */

    /*              */
    container.classList.add("pl_snackbar");
    container.id = this.settings.id || window.o_util.misc.guid();

    if (this.settings.containerClass) {
      container.classList.add(this.settings.containerClass);
    }

    /*                */
    textContent.classList.add("pl_copy100");
    textContent.classList.add("pl_snackbar__content");
    textContent.innerText = this.settings.text;
    container.appendChild(textContent);

    /*                     */
    if (this.settings.actionLinkText && this.settings.actionFunction) {
      actionLink = document.createElement("a");
      actionLink.classList.add("pl_link100--underline");
      actionLink.classList.add("pl_snackbar__action-link");

      actionLink.innerText = this.settings.actionLinkText;
      actionLink.addEventListener("click", this.settings.actionFunction);

      container.appendChild(actionLink);
    }

    closeIcon.classList.add("pl_icon");
    closeIcon.setAttribute("role", "img");
    closeIcon.innerHTML = `<use href="/assets-static/icons/pl_icon_close.svg#pl_icon_close" xlink:href="/assets-static/icons/pl_icon_close.svg#pl_icon_close"></use>`;
    container.appendChild(closeIcon);

    return {
      container,
      textContent,
      actionLink,
      closeIcon,
    };
  }

  close(): void {
    this.container.classList.add("pl_snackbar--fade-out");

    const cleanupAndNotify = (): void => {
      this.container.parentNode?.removeChild(this.container);

      window.o_global
        .events<SnackbarEvents>()
        .emit(SNACKBAR_EVENTS.CLOSED, { id: this.container.id });

      if (this.settings.closeCallback) {
        this.settings.closeCallback();
      }
    };

    /*                                                         */
    const fallbackTimeout = setTimeout(cleanupAndNotify, 500);

    this.container.addEventListener("animationend", () => {
      clearTimeout(fallbackTimeout);
      cleanupAndNotify();
    });
  }

  open(): void {
    const startTimer = (): void => {
      if (!this.#activeSnackbarTimerId) {
        this.#start = Date.now();
        this.#activeSnackbarTimerId = window.setTimeout(() => this.close(), this.#remaining);
      }
    };

    const pauseTimer = (): void => {
      if (this.#activeSnackbarTimerId) {
        window.clearTimeout(this.#activeSnackbarTimerId);
        this.#activeSnackbarTimerId = null;
        this.#remaining -= Date.now() - this.#start;
      }
    };

    const setupPauseOnHover = (): void => {
      if (window.matchMedia("(hover: hover)").matches) {
        this.container.addEventListener("mouseenter", () => {
          pauseTimer();
        });

        this.container.addEventListener("mouseleave", () => {
          startTimer();
        });
      }
    };

    this.closeIcon?.addEventListener("click", () => this.close());

    let hostContainer = document.querySelector(
      this.settings.hostContainerSelector ?? ".gridContainer",
    );

    const focusedDialog = document.querySelector(".pl_focussed-dialog");
    if (focusedDialog) {
      hostContainer = focusedDialog;
    }

    const openSheet = document.querySelector(".pl_sheet--open");
    if (openSheet) {
      hostContainer = openSheet;
    }

    const focusedDialogActions = document.querySelector(".pl_focussed-dialog__actions");

    const rootElement: Element | null = hostContainer ?? document.getElementsByTagName("body")[0];

    const hostContainerDimensions = hostContainer?.getBoundingClientRect();

    /*                                                        */
    if (openSheet) {
      this.container.classList.add("pl_snackbar--in-sheet");
    } else if (
      hostContainerDimensions &&
      window.o_global.breakpoint.isBreakpointActive(["s"]) === false &&
      window.o_global.breakpoint.isBreakpointActive(["m"]) === false
    ) {
      this.container.style.maxWidth = `${hostContainerDimensions.width - 16}px`;
    }

    if (!openSheet && focusedDialogActions) {
      const actionsContainerDimensions = focusedDialogActions.getBoundingClientRect();
      /*                                                                  */
      if (
        actionsContainerDimensions.top + actionsContainerDimensions.height ===
        window.innerHeight
      ) {
        this.container.style.bottom = `${actionsContainerDimensions.height + 16}px`;
      }
    }

    rootElement?.appendChild(this.container);

    if (
      !openSheet &&
      hostContainerDimensions &&
      window.o_global.breakpoint.isBreakpointActive(["s"]) === false &&
      window.o_global.breakpoint.isBreakpointActive(["m"]) === false
    ) {
      this.container.style.width = `${this.container.getBoundingClientRect().width}px`;

      this.container.style.left = `${
        hostContainerDimensions.left + hostContainerDimensions.width / 2
      }px`;
    }

    this.container.classList.add("pl_snackbar--fade-in");

    setTimeout(() => {
      if (this.settings.openCallback) {
        this.settings.openCallback();
      }

      /*                            */
      if (this.settings.trackingObject) {
        submitEvent(this.settings.trackingObject);
      }

      window.o_global
        .events<SnackbarEvents>()
        .emit(SNACKBAR_EVENTS.OPENED, { id: this.container.id });

      startTimer();
      setupPauseOnHover();
    }, 100);
  }
}
