import { closeContext, createContext, submitEvent } from "@otto-ec/tracking-bct";

/**
 *
 *
 *
 *
 */
window.o_global.pali.layerBuilder = (function () {
  const defaultSettings = {
    width: null, /*                 */
    widthL: null, /*                       */
    widthXL: null, /*                        */
    openCallback: false, /*                                                                           */
    closeCallback: false, /*                                                 */
    createTrackingContext: true, /*                                      */
    url: null, /*                             */
    content: "", /*                                                                      */
    trackingKey: null, /*                                       */
    trackingEvent: null, /*                                       */
    trackingObject: null, /*                                       */
    layerClass: "p_layer", /*                                         */
    modal: true, /*                                                                                  */
    menuContent: null, /*                                              */
    lockCurtain: false, /*                                    */
    hideBackButton: false, /*                                                 */
    headerDisplayMode: "visible", /*                                                                                              */
    statusCallbacks: null, /*                                                                     */
    altUrl: null, /*                                                                                      */
  };

  /**
 *
 *
 *
 */
  function Layer(initialParameters) {
    let settings = {};
    const _self = this;
    const modalContainer = document.createElement("div");
    let modalWrapper = document.createElement("div");
    const modalCurtain = document.createElement("div");
    let modalContent = document.createElement("div");
    const modalHeader = document.createElement("div");
    const modalClose = document.createElement("a");
    const modalScreenreaderClose = document.createElement("a");
    const defaultBackButton =
      '<button class="js_PaliLayerBack p_btn100--3rd p_layer__button"><i>&lt;&nbsp;</i>Zurück</button>';
    let isOpen = false;
    let contentStack = []; /*                          */
    let settingsStack = []; /*                       */

    /*             */
    settings = window.o_util.core.extend(defaultSettings, initialParameters, true, true);

    /*                                     */
    modalContainer.ariaModal = true;
    modalContainer.setAttribute("role", "dialog"); /*                                    */
    modalScreenreaderClose.setAttribute("tabindex", "0"); /*                                                         */
    modalScreenreaderClose.setAttribute("aria-label", "Dialog schließen");

    /*                 */
    modalCurtain.className = "p_curtain";
    modalContainer.className = settings.layerClass;
    modalWrapper.className = "p_layer__wrapper";
    modalWrapper.setAttribute("id", `p_layer__wrapper_${contentStack.length}`);
    modalContent.className = "p_layer__content";
    modalHeader.className = "p_layer__header";
    modalClose.className = "p_layer__close";
    modalClose.innerHTML = "X";
    modalScreenreaderClose.className = "p_layer_screenreader__close";
    modalScreenreaderClose.innerHTML = "Dialog schließen"; /*                                                 */
    modalContainer.id = "p_layer";

    modalContainer.classList.add("p_layer--hidden");

    if (settings.headerDisplayMode === "removed") {
      modalContainer.classList.add("p_layer--headerRemoved");
    } else if (settings.headerDisplayMode === "removedOnSmallScreens") {
      modalContainer.classList.add("p_layer--headerRemovedOnSmallScreens");
    }

    /**
 *
 *
 *
 */
    this.getContainerElement = function () {
      return modalContainer;
    };

    /**
 *
 *
 *
 */
    this.getCurtainElement = function () {
      return modalCurtain;
    };

    /**
 *
 *
 *
 */
    this.setWidth = function (width) {
      let maxWidth;
      const minBorder = 32;

      /*                                                                                     */
      maxWidth = Math.max(parseInt(document.documentElement.clientWidth, 10) - 2 * minBorder, 768);

      modalContainer.style.width = width;
      modalContainer.style.maxWidth = `${maxWidth}px`;
      modalContainer.classList.add("p_layer--forcedWidth");
    };

    /**
 *
 *
 *
 *
 *
 */
    this.setBreakpointWidth = function (widthL, widthXL, width) {
      switch (window.o_global.device.breakpoint.getCurrentBreakpoint()) {
        case "l":
          _self.setWidth(widthL || width);
          break;
        case "xl":
          _self.setWidth(widthXL || width);
          break;
        default:
          _self.setWidth(width);
      }
    };

    /**
 *
 *
 *
 */
    this.executePreloadScripts = function (element) {
      if (window.preload_polyfill_invoke) {
        window.preload_polyfill_invoke(element);
      }
    };

    /**
 *
 *
 *
 *
 *
 *
 */
    this._setElementContent = function (content, element) {
      if (typeof content === "string") {
        element.innerHTML = content;
      } else {
        if (
          element.classList.contains("p_layer__content") &&
          !content.classList.contains("p_layer__content")
        ) {
          content.classList.add("p_layer__content");
        }

        /*              */
        /*                                     */
        element.parentNode.replaceChild(content, element);
        /*             */

        /*                                          */
        if (element === modalContent) {
          modalContent = content;
        }
      }

      /*                                                     */
      if (element === modalWrapper) {
        modalContent = modalWrapper.getElementsByClassName("p_layer__content")[0];
      }

      /*                   */
      window.o_util.hardcore.executeInlineScripts(element);

      /*                                                                                                    */
      _self.executePreloadScripts(element);

      _self._parseLegacyDataAttributes();
    };

    /**
 *
 *
 *
 */
    this.setContent = function (content) {
      _self._setElementContent(content, modalContent);
    };

    /**
 *
 *
 *
 *
 *
 */
    this.setWrapperContent = function (content) {
      _self._setElementContent(content, modalWrapper);
    };

    /**
 *
 *
 *
 */
    this._reloadSite = function () {
      window.location.reload(true);
    };

    /**
 *
 *
 *
 *
 */
    this._handleAjaxResult = function (result, callback) {
      const defaultCallbacks = {
        200(response) {
          _self.setContent(response.responseText);
          if (typeof callback === "function") {
            callback(response.responseText, response.status);
          }
        },
        401() {
          /*                                                                              */
          _self._reloadSite();
        },
        default() {
          const errorTemplate =
            '<div class="p_message p_message--error p_layer--error">' +
            "<b>Entschuldigung!</b> Aufgrund von technischen Problemen kann der Inhalt leider nicht " +
            "geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut." +
            "</div>";

          _self.setContent(errorTemplate);
          if (typeof callback === "function") {
            callback(errorTemplate, result.status);
          }
        },
      };
      /*                                                             */
      const statusCallbacks = window.o_util.core.extend(
        defaultCallbacks,
        settings.statusCallbacks,
        true,
        false,
      );

      if (statusCallbacks[result.status]) {
        statusCallbacks[result.status](result);
      } else {
        statusCallbacks.default();
      }
    };

    /**
 *
 *
 *
 *
 */
    this.setContentFromURL = function (url, callback) {
      _self._addLoaderToContent();

      window.o_util.ajax.get(url, function (ajaxResult) {
        _self._handleAjaxResult(ajaxResult, callback);
      });
    };

    /**
 *
 *
 *
 */
    this._addLoaderToContent = function () {
      let loader;

      loader = document.createElement("div");
      loader.className = "p_loader200";
      loader.innerHTML = "Wird geladen...";

      modalContent.insertBefore(
        loader,
        modalContent.hasChildNodes() ? modalContent.childNodes[0] : null,
      );
    };

    /**
 *
 */
    this.createTrackingContext = function () {
      /*                                                                   */
      if (!!window.AS.toggles && !!window.AS.toggles.isPreview) {
        return;
      }

      try {
        /*                            */
        createContext(modalWrapper.id, `${document.location.pathname}layer`, {})
      } catch (e) {}
    };

    /**
 *
 */
    this.closeTrackingContext = function () {
      /*                                                                   */
      if (!!window.AS.toggles && !!window.AS.toggles.isPreview) {
        return;
      }

      closeContext().catch(() => {});
    };

    /**
 *
 *
 *
 *
 */
    this._parseLegacyDataAttributes = function () {
      const settingDiv = modalContent.querySelectorAll(
        "[data-title], [data-callback], .shoppagesLayer",
      )[0];

      if (settingDiv === undefined) {
        return true;
      }

      /*              */
      if (modalContent.getElementsByClassName("p_layer__body").length <= 0) {
        settingDiv.classList.add("p_layer__body");
      }

      /*               */
      const title = settingDiv.getAttribute("data-title");

      if (!!title && modalContent.getElementsByClassName("p_layer__headline").length <= 0) {
        const headline = document.createElement("div");

        headline.className = "p_layer__headline";
        headline.innerHTML = title;
        modalContent.insertBefore(headline, settingDiv);
      }

      /*                       */
      const duplicatedTitle = settingDiv.getElementsByClassName("js_layerTitle")[0];

      if (duplicatedTitle) {
        duplicatedTitle.parentNode.removeChild(duplicatedTitle);
      }

      /*                */
      const callback = settingDiv.getAttribute("data-callback");

      if (callback) {
        window.o_util.core.convertStringToFunction(callback, window)();
      }
    };

    /**
 *
 */
    this.open = function () {
      if (!isOpen) {
        /*          */
        window.o_util.history.setScrollRestoration("manual");

        _self._pushHistory();
        _self._hideLayerOnSmallScreens();

        /*         */
        if (settings.width !== null) {
          _self.setWidth(settings.width);
        }

        /*                             */
        if (settings.widthL !== null || settings.widthXL !== null) {
          _self.setBreakpointWidth(settings.widthL, settings.widthXL, settings.width);

          /*                                    */
          window.o_global.device.breakpoint.registerChangeListener(function () {
            _self.setBreakpointWidth(settings.widthL, settings.widthXL, settings.width);
          });
        }

        /*              */
        /*                                                                     */
        if (!!settings.modal && !settings.lockCurtain) {
          modalCurtain.addEventListener("click", _self.close);
          modalHeader.addEventListener("click", _self.close);
        } else {
          modalClose.addEventListener("click", _self.close);
        }
        modalScreenreaderClose.addEventListener("click", _self.close);

        modalWrapper.appendChild(modalContent);

        /*            */
        if (settings.content) {
          _self.setContent(settings.content);
        } else if (settings.url) {
          _self.setContentFromURL(settings.url, _self._postOpenCallback);
        } else {
          _self.setContent("");
          _self._addLoaderToContent();
        }

        _self._setLayerPosition();

        document.body.insertBefore(modalContainer, document.body.children[0]);

        /*                   */
        if (settings.modal) {
          modalCurtain.classList.add("p_curtain--hidden");
          document.body.insertBefore(modalCurtain, document.body.children[0]);
        }

        isOpen = true;

        if (settings.headerDisplayMode !== "removed") {
          modalContainer.appendChild(modalHeader);
          modalHeader.appendChild(modalClose);
        }

        modalContainer.appendChild(modalWrapper);

        /*                                                                             */
        /*                                        */
        if (settings.headerDisplayMode !== "visible") {
          modalContainer.appendChild(modalScreenreaderClose);
        }

        /*         */
        if (settings.menuContent) {
          _self.setMenuContent(settings.menuContent);
        }

        /*                                */
        window.o_global.pali.layer.setActiveLayer(_self);

        /*                                                                     */
        /*              */
        /*                     */
        /*                                    */
        window.getComputedStyle(modalContainer).opacity;
        /*                   */
        /*             */

        _self._slideInLayerOnSmallScreens();
        modalContainer.classList.remove("p_layer--hidden");

        if (settings.modal) {
          modalCurtain.classList.remove("p_curtain--hidden");
        }

        /*                                                                         */
        if (!settings.url) {
          _self._postOpenCallback();
        }

        try {
          /*                                       */
          window.o_global.pali.scrollTopButton.hideButton();
        } catch (e) {}

        window.addEventListener("popstate", _self._onPopState);

        /*                                                                                                    */
        if (document.activeElement) {
          if (typeof document.activeElement.blur === "function") {
            document.activeElement.blur();
          }
          modalContainer.focus();
        }
      }
    };

    /**
 *
 *
 *
 */
    this._setLayerPosition = function () {
      const documentScrollTop =
        (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const offsetTop = window.o_global.device.breakpoint.isBreakpointActive(["s", "m"]) ? 0 : 28;

      modalContainer.style.top = `${documentScrollTop + offsetTop}px`;
    };

    /**
 *
 *
 *
 */
    this._hideLayerOnSmallScreens = function () {
      if (window.o_global.breakpoint.isBreakpointActive(["s", "m"])) {
        window.o_util.misc.setVendorStyle(
          modalContainer,
          "transform",
          `translateY(${document.body.offsetHeight}px)`,
        );
      }
    };

    /**
 *
 *
 *
 */
    this._slideInLayerOnSmallScreens = function () {
      /*                                                                                                      */
      /*                                                                                     */
      if (window.o_global.breakpoint.isBreakpointActive(["s", "m"])) {
        window.o_util.misc.setVendorStyle(modalContainer, "transform", "");
      }
    };

    /**
 *
 *
 *
 */
    this._postOpenCallback = function () {
      /*                     */
      if (!!settings.trackingEvent && !!settings.trackingKey) {
        const event = new CustomEvent(settings.trackingEvent, {
          detail: {
            trackingKey: settings.trackingKey,
          },
        });

        window.dispatchEvent(event);
      }

      /*                       */
      if (settings.createTrackingContext) {
        _self.createTrackingContext();
      }

      /*                                                                  */
      if (settings.openCallback) {
        _self.executeCallback();
      }

      /*                            */
      if (settings.trackingObject) {
        try {
          /*                                                                     */
          submitEvent(JSON.parse(settings.trackingObject));
        } catch (e) {}
      }
    };

    /**
 *
 */
    this.executeCallback = function () {
      settings.openCallback();
    };

    /**
 *
 *
 *
 */
    this._removeLayerAndCurtain = function () {
      /*                       */
      try {
        document.body.removeChild(modalContainer);
        if (settings.modal) {
          document.body.removeChild(modalCurtain);
        }
      } catch (e) {}
    };

    /**
 *
 *
 *
 */
    this.close = function (preventHistoryClear) {
      /*                             */
      const transitionEvent = window.o_util.event.whichTransitionEndEvent();

      /**
 *
 *
 *
 */
      function _closeHandler() {
        modalContainer.removeEventListener(transitionEvent, _closeHandler);
        _self._removeLayerAndCurtain();
      }

      if (isOpen) {
        if (typeof transitionEvent !== "undefined") {
          modalContainer.addEventListener(transitionEvent, _closeHandler);
        } else {
          /*    */
          _self._removeLayerAndCurtain();
        }

        _self._hideLayerOnSmallScreens();
        modalContainer.classList.add("p_layer--hidden");
        modalCurtain.classList.add("p_curtain--hidden");

        isOpen = false;

        /*                                  */
        window.o_global.pali.layer.setActiveLayer();

        /*                              */
        if (settings.createTrackingContext) {
          _self.closeTrackingContext();
        }

        window.removeEventListener("popstate", _self._onPopState);

        /*                                                                                                  */
        if (typeof preventHistoryClear !== "boolean" || !preventHistoryClear) {
          _self._clearHistory();
        } else {
          _self._removeInitialLayerHistory();
        }

        /*                  */
        if (settings.closeCallback) {
          settings.closeCallback();
        }

        /*                                     */
        if (settingsStack.length > 0) {
          settings = window.o_util.core.clone(settingsStack[0]);
        }

        /*                                                                */
        contentStack = [];
        settingsStack = [];

        /*                                                                         */
        setTimeout(function () {
          window.o_util.history.resetScrollRestoration();
        }, 0);
      }
    };

    /**
 *
 *
 *
 */
    this.setMenuContent = function (menu) {
      /*                         */
      if (modalWrapper.getElementsByClassName("p_layer__menu").length === 0) {
        const newModalMenu = document.createElement("div");

        newModalMenu.classList.add("p_layer__menu");
        newModalMenu.innerHTML = menu;
        modalWrapper.insertBefore(newModalMenu, modalContent);
      }
    };

    /**
 *
 *
 *
 */
    this._createEmptyWrapper = function () {
      const newWrapper = document.createElement("div");

      newWrapper.classList.add("p_layer__wrapper");
      newWrapper.setAttribute("id", `p_layer__wrapper_${contentStack.length}`);
      modalWrapper = newWrapper;
      modalContainer.appendChild(modalWrapper);

      const newModalContent = document.createElement("div");

      newModalContent.classList.add("p_layer__content");
      modalContent = newModalContent;
      modalWrapper.appendChild(modalContent);
    };

    /**
 *
 *
 *
 */
    this.switchLayerContent = function (initParameters) {
      /*                                   */
      if (settings.createTrackingContext) {
        _self.closeTrackingContext();
      }

      /*                                                */
      settingsStack.push(window.o_util.core.clone(settings));
      /*             */
      settings = window.o_util.core.extend(defaultSettings, initParameters, true, true);

      modalWrapper.classList.add("p_layer__wrapper-toggled");
      modalWrapper.classList.add("hide");
      contentStack.push(`p_layer__wrapper_${contentStack.length}`);

      /*                             */
      _self._createEmptyWrapper();

      /*                                        */
      modalContainer.classList.add("p_layer--switchedContent");

      if (settings.content) {
        _self.setContent(settings.content);
      } else if (settings.url) {
        _self.setContentFromURL(settings.url, _self._postOpenCallback);
      }

      if (!settings.hideBackButton && !settings.menuContent) {
        _self.setMenuContent(defaultBackButton);
      } else if (settings.menuContent) {
        _self.setMenuContent(settings.menuContent);
      }

      /*                                                     */
      if (!settings.url) {
        _self._postOpenCallback();
      }

      _self._pushHistory();
      _self.resetViewport(); /*                                     */
    };

    /**
 *
 */
    this.revertLayerContent = function () {
      _self._historyBack();
    };

    /**
 *
 *
 *
 */
    this._revertLayerContent = function () {
      if (modalWrapper) {
        modalContainer.removeChild(modalWrapper);
      }

      modalWrapper = document.getElementById(contentStack.pop());

      if (!modalWrapper) {
        return;
      }

      modalContent = modalWrapper.getElementsByClassName("p_layer__content")[0];

      modalWrapper.classList.toggle("hide");
      modalWrapper.classList.toggle("p_layer__wrapper-toggled");

      /*                         */
      if (settings.createTrackingContext) {
        _self.closeTrackingContext();
      }

      /*                  */
      if (settings.closeCallback) {
        settings.closeCallback();
      }

      settings = window.o_util.core.clone(settingsStack.pop());

      /*                                            */
      if (settings.createTrackingContext) {
        _self.createTrackingContext();
      }

      _self.resetViewport();
    };

    /**
 *
 */
    this.resetViewport = function () {
      const offsetTop = window.o_global.device.breakpoint.isBreakpointActive(["s", "m"]) ? 0 : 28;

      window.o_util.animation.scrollTo(modalContainer.offsetTop - offsetTop, 500);
    };

    /**
 *
 *
 *
 *
 *
 *
 */
    this._resetViewport = function () {
      _self.resetViewport();
    };

    /**
 *
 *
 *
 *
 *
 */
    this._hasHistoryAPI = function () {
      if (!window.history || !window.history.pushState) {
        return false;
      }

      /*                     */
      /*                                                                                        */
      if (Function("/*                                         */")()) {
        return false;
      }
      /*                   */

      /*                                                     */
      if (navigator.userAgent.indexOf("CriOS") !== -1) {
        return false;
      }

      return true;
    };

    /**
 *
 *
 *
 */
    this._pushHistory = function () {
      if (_self._hasHistoryAPI()) {
        if (!isOpen) {
          _self._addLayerIdToHistoryState();
        }

        window.history.pushState(
          {
            layerId: contentStack.length,
          },
          document.title,
        );
      }
    };

    /**
 *
 *
 *
 */
    this._historyBack = function () {
      if (_self._hasHistoryAPI()) {
        window.history.back();
      } else {
        _self._revertLayerContent();
      }
    };

    /**
 *
 *
 *
 */
    this._addLayerIdToHistoryState = function () {
      window.history.pushState(
        {
          layerId: -1,
        },
        document.title,
      );
    };

    /**
 *
 *
 *
 */
    this._removeInitialLayerHistory = function () {
      if (_self._hasHistoryAPI()) {
        window.history.go(-1);
      }
    };

    /**
 *
 *
 *
 */
    this._clearHistory = function () {
      if (_self._hasHistoryAPI()) {
        window.history.go(-1 * (contentStack.length + 2));
      }
    };

    /**
 *
 *
 *
 *
 *
 *
 */
    this._onPopState = function (event) {
      if (!!event.state && typeof event.state.layerId !== "undefined") {
        if (event.state.layerId >= 0) {
          _self._revertLayerContent();
        } else {
          _self.close(true);
        }
      }
    };
  }

  return Layer;
})();

/**
 *
 *
 */
window.o_global.pali.layer = (function () {
  const settingsMap = {
    url: "href",
    width: "data-layer-width",
    widthL: "data-layer-width-l",
    widthXL: "data-layer-width-xl",
    createTrackingContext: "data-layer-createtrackingcontext",
    trackingKey: "data-trackingkey",
    trackingEvent: "data-trackingevent",
    trackingObject: "data-trackingobject",
    layerClass: "data-layer-class",
    menuContent: "data-layer-menu-content",
    modal: "data-layer-modal",
    content: "data-layer-content",
    lockCurtain: "data-layer-lockcurtain",
    hideBackButton: "data-layer-hidebackbutton",
    headerDisplayMode: "data-layer-headerdisplaymode",
    openCallback: "data-layer-opencallback",
    closeCallback: "data-layer-closecallback",
    statusCallbacks: "data-layer-status-callbacks",
    altUrl: "data-layer-href",
    base64Url: "data-layer-ub64e",
  };
  let activeLayer; /*                     */

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  function _parseDataAttributes(element) {
    const settings = [];

    for (const key in settingsMap) {
      if (settingsMap.hasOwnProperty(key)) {
        const attribute = element.getAttribute(settingsMap[key]);

        if (attribute) {
          let parsedAttribute = null;

          /*                            */
          switch (attribute) {
            case "false":
              parsedAttribute = false;
              break;
            case "true":
              parsedAttribute = true;
              break;
            case "null":
              parsedAttribute = null;
              break;
            default:
              parsedAttribute = attribute;
          }

          if (key === "statusCallbacks") {
            try {
              parsedAttribute = JSON.parse(attribute);
            } catch (e) {
              throw new Error("Could not parse data-layer-status-callbacks. Not valid json");
            }

            for (const statusCode in parsedAttribute) {
              if (parsedAttribute.hasOwnProperty(statusCode)) {
                parsedAttribute[statusCode] = window.o_util.core.convertStringToFunction(
                  parsedAttribute[statusCode],
                  window,
                );
              }
            }
          } else if (key.indexOf("Callback") > -1) {
            parsedAttribute = window.o_util.core.convertStringToFunction(attribute, window);
          }

          settings[key] = parsedAttribute;
        }
      }
    }

    return settings;
  }

  /**
 *
 *
 *
 *
 *
 */
  function _initLayer(event) {
    let trigger = event.target;
    const isValidTrigger = window.o_util.misc.isValidMouseButton(event, 1);

    if (!trigger.classList.contains("js_openInPaliLayer")) {
      trigger = window.o_util.dom.getParentByClassName(trigger, "js_openInPaliLayer");
    }

    if (!!trigger && !!isValidTrigger) {
      const attributes = _parseDataAttributes(trigger);

      if (attributes.base64Url) {
        attributes.url = window.atob(attributes.base64Url);
      }

      if (attributes.altUrl) {
        attributes.url = attributes.altUrl;

        /*                                                         */
        if (isValidTrigger && (event.metaKey || event.ctrlKey)) {
          return;
        }
      }

      if (window.o_util.dom.hasClassInParents(trigger, "p_layer")) {
        /*              */
        activeLayer.switchLayerContent(attributes);
      } else {
        /*         */
        const layer = new window.o_global.pali.layerBuilder(attributes);

        layer.open();
      }

      window.o_util.event.stop(event);
    }
  }

  /**
 *
 */
  function init() {
    /*                                                   */
    /*                                                                                */
    window.o_global.eventLoader.onReady(40, function () {
      window.o_util.event.delegate(document, "click", ".js_openInPaliLayer", function (event) {
        _initLayer(event);
      });

      window.o_util.event.delegate(document, "click", ".js_PaliLayerBack", function (event) {
        activeLayer.revertLayerContent();
        window.o_util.event.stop(event);
      });

      window.o_util.event.delegate(document, "click", ".js_PaliLayerClose", function (event) {
        activeLayer.close();
        window.o_util.event.stop(event);
      });
    });
  }

  /**
 *
 *
 *
 */
  function setActiveLayer(layer) {
    if (layer) {
      if (activeLayer !== undefined) {
        activeLayer.close();
      }

      activeLayer = layer;
    } else {
      activeLayer = undefined;
    }
  }

  /**
 *
 *
 *
 */
  function getActiveLayer() {
    return activeLayer;
  }

  return {
    init,
    setActiveLayer,
    getActiveLayer,
  };
})();

window.o_global.pali.layer.init();
