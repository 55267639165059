/*                        */
window.o_global.pali.androidSelectBoxFix =
  window.o_global.pali.androidSelectBoxFix ||
  (function () {
    /**
 *
 *
 */
    function androidSelectBoxFix() {
      const nua = navigator.userAgent;
      const isAndroid =
        nua.indexOf("Mozilla/5.0") > -1 &&
        nua.indexOf("Android ") > -1 &&
        nua.indexOf("AppleWebKit") > -1 &&
        nua.indexOf("Chrome") === -1;

      if (isAndroid) {
        const selectFields = document.getElementsByClassName("p_form__select");

        for (let i = selectFields.length - 1; i >= 0; --i) {
          const reg = new RegExp("p_form__select", "g");

          selectFields[i].className = selectFields[i].className.replace(
            reg,
            "p_form__select--androidworkaround"
          );
        }
      }
    }

    /**
 *
 */
    function iosActiveStateFix() {
      document.addEventListener("touchstart", function () {});
    }

    window.o_global.eventLoader.onReady(100, function () {
      iosActiveStateFix();
      androidSelectBoxFix();
    });

    return {
      androidSelectBoxFix,
    };
  })();
