import { globalEvents } from "../../common/src/events.main";
import { initSheet } from "./pl_sheetHandler";
import { Sheet } from "./Sheet";
import type { SheetCloseTypes, SheetEvents } from "./sheetEvents";

window.o_global.pali.sheetBuilder = Sheet;
window.o_global.pali.sheet = Sheet;

globalEvents.once("assets.events.documentReady", () => {
  window.o_util.event.delegate(document as never, "click", ".js_openInPaliSheet", (event) => {
    initSheet(event, Sheet);
  });

  window.o_util.event.delegate(document as never, "keydown", ".js_openInPaliSheet", (event) => {
    if (
      (event.key === "Enter" || event.key === " ") &&
      !window.o_global.pali.sheet.getActiveSheet()
    ) {
      initSheet(
        {
          target: event.target,
          metaKey: event.metaKey,
          ctrlKey: event.ctrlKey,
        } as MouseEvent,
        Sheet,
      );
    }
  });
});

export type { Sheet, SheetEvents, SheetCloseTypes };
