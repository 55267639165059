import { Snackbar } from "./Snackbar";
import { SnackbarEvents, SnackbarSettings, SNACKBAR_EVENTS } from "./snackbarEvents";

(function setup() {
  const queue: Snackbar[] = [];

  window.o_global
    .events<SnackbarEvents>()
    .on(SNACKBAR_EVENTS.TRIGGER, (settings: SnackbarSettings) => {
      /*                                                                               */
      if (queue.length > 0) {
        queue[0].close();
        queue.splice(0, 1);
      }

      const snackbar = new Snackbar(settings);
      snackbar.open();

      queue.push(snackbar);
    });

  window.o_global.events<SnackbarEvents>().on(SNACKBAR_EVENTS.CLOSED, () => {
    if (queue.length > 1) {
      /*                                                   */
      queue.splice(0, 1);
    }
  });
})();

export type { SnackbarEvents, SnackbarSettings };
