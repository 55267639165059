/**
 *
 */
function initClickListeners() {
  document.addEventListener("click", function (event) {
    const { target } = event;

    /*                                    */
    if (target.classList.contains("js_message__close")) {
      const message = window.o_util.dom.getParentByClassName(target, "p_message");

      if (message) {
        message.style.display = "none";
      }
    }
  });
}

window.o_global.eventLoader.onReady(10, initClickListeners);
