import { clickHandler, close, open } from "./tooltip";
import { TooltipEvents, TOOLTIP_EVENTS } from "./tooltipEvents";

const initTooltips = (selector?: string): void => {
  const tooltipHosts = document.querySelectorAll(
    `${selector ?? ""} .js_openTooltip`
  ) as NodeListOf<HTMLElement>;

  tooltipHosts.forEach((tooltipHost) => {
    const { tooltipOpenOnLoad } = tooltipHost.dataset;

    tooltipHost.addEventListener("click", clickHandler);

    if (tooltipOpenOnLoad === "true") {
      open(tooltipHost);

      tooltipHost.removeAttribute("data-tooltip-open-on-load");
    }
  });

  window.o_global.events<TooltipEvents>().emit(TOOLTIP_EVENTS.INITIALISED);
};

window.o_global.events<TooltipEvents>().on("assets.tooltip.init", (selector?: string) => {
  initTooltips(selector);
});

window.o_global.events<TooltipEvents>().on("assets.tooltip.close", ({ id }: { id: string }) => {
  const tooltipElement = document.getElementById(id);
  const tooltipHostElement = document.querySelector<HTMLElement>(`[data-tooltip-id="${id}"]`);

  if (tooltipElement && tooltipHostElement) {
    const { dataTooltipOpenOnlyOnce } = tooltipHostElement.dataset;
    close(tooltipElement, tooltipHostElement, dataTooltipOpenOnlyOnce === "true");
  }
});

window.o_global.eventLoader.onReady(130, () => {
  initTooltips("body");
});

export type { TooltipEvents };
