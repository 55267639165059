/*                                         */
window.o_global.eventQBus.on("assets.label.init", (selector = "") => {
  const elements = document.querySelectorAll(
    `${selector} div:not(.pl_dropdown) > .js-p_label,${selector} div:not(.pl_dropdown) > .js-pl_label`
  );

  [].forEach.call(elements, (el) => {
    if (el.previousElementSibling) {
      el.previousElementSibling.addEventListener("change", function () {
        this.setAttribute("value", this.value);
      });
    }
    window.setTimeout(() => {
      el.classList.remove("js-p_label", "js-pl_label");
    }, 100);
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.label.init", "body");
});
