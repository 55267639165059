/**
 *
 *
 */
export interface Presets {
  /**
 *
 */
  animationSpeed: number;
  /**
 *
 */
  animationEasing: string;
}

window.o_global.pali.presets = { animationSpeed: 500, animationEasing: "easeInOutCubic" };
