import { closeContext, createContext, replaceContext } from "@otto-ec/tracking-bct";
import { SHEET_EVENTS, SheetEvents } from "./sheetEvents";

export type HandleTrackingContextOptions = { oldContext: boolean; newContext: boolean };

export function registerTrackingContextHandler(callback: () => void): void {
  window.o_global.events<SheetEvents>().once(SHEET_EVENTS.contentLoaded, callback);
}

export function createTrackingContext(sheetId: string): void {
  createContext(sheetId, `${document.location.pathname}sheet`);
}

export function closeTrackingContext(): void {
  closeContext();
}

export function replaceTrackingContext(sheetId: string): void {
  /*                                       */
  replaceContext(sheetId, `${document.location.pathname}sheet`, {});
}

const trackingContextActions: {
  check: (options: HandleTrackingContextOptions) => boolean;
  action: (sheetId: string) => void;
}[] = [
  {
    check: ({ oldContext, newContext }) => oldContext && newContext,
    action: replaceTrackingContext,
  },
  {
    check: ({ oldContext, newContext }) => oldContext && !newContext,
    action: closeTrackingContext,
  },
  {
    check: ({ oldContext, newContext }) => !oldContext && newContext,
    action: createTrackingContext,
  },
];

export function handleTrackingContext(
  sheetId: string,
  options: {
    oldContext: boolean;
    newContext: boolean;
  },
): void {
  trackingContextActions.find(({ check }) => check(options))?.action(sheetId);
}
