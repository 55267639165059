/*                                                         */
import { sendCustomError } from "@otto-ec/global-resources/apm";
import { submitEvent } from "@otto-ec/tracking-bct";
import { SheetGestureHandler } from "./SheetGestureHandler";
import { getActiveSheet } from "./pl_sheetHandler";
import { SHEET_CLOSE_TYPE, SHEET_EVENTS, SheetCloseTypes, SheetEvents } from "./sheetEvents";
import {
  closeTrackingContext,
  createTrackingContext,
  handleTrackingContext,
  registerTrackingContextHandler,
} from "./sheetTrackingHandler";
import { DefaultSheetSettings, SheetAPI, SheetElementContent, SheetSettings } from "./sheetTypes";

const defaultSettings: DefaultSheetSettings = {
  content: "", /*                                                                      */
  title: "", /*                          */
  headerContent: "", /*                                      */
  menuContent: "", /*                                    */
  menuBarVertical: false, /*                                                                                                */
  showBackButton: true, /*                                                          */
  showCloseButton: true, /*                                         */
  lockMobileHeight: true, /*                                                                                                                                                  */
  createTrackingContext: true, /*                                                                                  */
};

export class Sheet {
  id: string;

  settings: SheetSettings;

  sheetCurtain: HTMLElement;

  sheetContainer: HTMLElement;

  sheetHeader: HTMLElement;

  sheetTitle: HTMLElement;

  sheetBackButton: HTMLElement | undefined;

  sheetCloseButton: HTMLElement | undefined;

  sheetContent: HTMLElement;

  sheetMenuBar: HTMLElement;

  isOpen = false;

  settingsStack: SheetSettings[] = []; /*                       */

  contentStack: string[] = []; /*                          */

  gestureHandler = new SheetGestureHandler({ sheet: this });

  /**
 *
 *
 *
 */
  //
  /*       */
  //
  constructor(initialParameters: Partial<SheetSettings>) {
    this.sheetCurtain = document.createElement("div");
    this.sheetContainer = document.createElement("div");
    this.sheetHeader = document.createElement("div");
    this.sheetTitle = document.createElement("div");
    this.sheetContent = document.createElement("div");
    this.sheetMenuBar = document.createElement("div");

    /*             */
    this.settings = { ...defaultSettings, ...initialParameters };

    try {
      if (typeof this.settings.trackingObject === "string") {
        this.settings.trackingObject = JSON.parse(this.settings.trackingObject);
      }
      /*                                                         */
    } catch (error) {
      /*            */
    }

    /*                          */
    this.settingsStack.push({ ...this.settings });

    /*                 */
    this.sheetCurtain.className = "pl_sheet-curtain";
    this.sheetContainer.className = "pl_sheet";
    this.sheetHeader.className = "pl_sheet__header";
    this.sheetTitle.className = "pl_headline100 pl_sheet__title";

    this.sheetContent.className = "pl_sheet__content";

    if (this.settings.fullBleedContent || this.settings.contentGreyBackground) {
      this.sheetContent.classList.add("pl_sheet__content--full-bleed");
    }

    if (this.settings.contentGreyBackground) {
      this.sheetContainer.classList.add("pl_sheet--grey-bg");
    }

    this.sheetMenuBar.classList.add("pl_sheet__menu-bar");

    if (this.settings.menuBarVertical) {
      this.sheetMenuBar.classList.add("pl_sheet__menu-bar--vertical");
    }

    this.sheetContent.setAttribute("id", `pl_sheet__content__${this.contentStack.length}`);

    /*                  */
    this.sheetContainer.classList.add("pl_sheet--hidden");

    /*     */
    this.id = this.settings.id || window.o_util.misc.guid();
    this.sheetContainer.id = this.id;
  }
  //
  //
  //

  /**
 *
 *
 */
  getContainer(): HTMLElement {
    return this.sheetContainer;
  }

  /**
 *
 *
 */
  getBackButton(): HTMLElement | undefined {
    return this.sheetBackButton;
  }

  /**
 *
 *
 */
  getCloseButton(): HTMLElement | undefined {
    return this.sheetCloseButton;
  }

  /**
 *
 *
 */
  getHeader(): HTMLElement {
    return this.sheetHeader;
  }

  /**
 *
 *
 */
  getTitle(): HTMLElement {
    return this.sheetTitle;
  }

  /**
 *
 *
 */
  getContent(): HTMLElement {
    return this.sheetContent;
  }

  /**
 *
 *
 */
  getMenuBar(): HTMLElement {
    return this.sheetMenuBar;
  }

  /**
 *
 *
 */
  getSettings(): Record<string, unknown> {
    return this.settings;
  }

  /**
 *
 */
  extractTitleFromContent(): void {
    const prerenderedContent = this.sheetContent.querySelector("[data-title]");

    if (prerenderedContent) {
      const parsedTitle = prerenderedContent.getAttribute("data-title");

      if (parsedTitle) {
        this.setTitle(parsedTitle);

        const duplicatedTitle = prerenderedContent.querySelector(".js_layerTitle, .js_sheetTitle");

        if (duplicatedTitle?.parentNode) {
          duplicatedTitle.parentNode.removeChild(duplicatedTitle);
        }
      }
    }
  }

  /**
 *
 */
  convertPresentLayerLinksToSheetLinks(): void {
    const foundPaliLayerLinks = this.sheetContent.querySelectorAll(
      ".js_openInPaliLayer",
    ) as NodeListOf<HTMLElement>;

    Array.from(foundPaliLayerLinks).forEach((layerLink) => {
      layerLink.classList.remove("js_openInPaliLayer");
      const sheetLink = document.createElement("a");

      sheetLink.innerText = layerLink.innerText;

      const newClassList = ["js_openInPaliSheet", ...Array.from(layerLink.classList)];

      sheetLink.classList.add(...newClassList);

      const layerLinkUb64e =
        layerLink.getAttribute("data-ub64e") ?? layerLink.getAttribute("data-layer-ub64e");

      if (layerLink instanceof HTMLAnchorElement) {
        sheetLink.setAttribute("data-sheet-url", layerLink.href);
      } else if (layerLinkUb64e) {
        sheetLink.setAttribute("data-sheet-ub64e", layerLinkUb64e);
      }

      layerLink.parentNode?.replaceChild(sheetLink, layerLink);
    });
  }

  /**
 *
 *
 *
 *
 *
 *
 */
  setElementContent(content: SheetElementContent, element: HTMLElement): void {
    if (typeof content === "string") {
      /*                                         */
      element.innerHTML = content;
      element.classList.remove("pl_sheet__content--loading");
    } else {
      this.clearChildren(element);

      if (Array.isArray(content)) {
        content.forEach((e) => element.appendChild(e));
      } else {
        element.appendChild(content);
      }
    }

    /*                   */
    window.o_util.hardcore.executeInlineScripts(element);

    /*                                                                                                    */
    if (window.invokePreload?.processPreloadsOnElement) {
      window.invokePreload.processPreloadsOnElement(element);
    }
  }

  /**
 *
 */
  removeSheetAndCurtain(): void {
    /*                       */
    document.getElementsByTagName("body")[0].removeChild(this.sheetContainer);
    document.getElementsByTagName("body")[0].removeChild(this.sheetCurtain);
  }

  /**
 *
 *
 *
 *
 */
  setBackButton(callback: EventListener, title?: string | HTMLElement): void {
    this.removeBackButton();

    this.buildBackButtonElement(title);

    this.sheetBackButton?.addEventListener("click", callback);
    this.sheetHeader.insertBefore(this.sheetBackButton as Node, this.sheetTitle as Node);
  }

  buildBackButtonElement(title?: string | HTMLElement): void {
    this.sheetBackButton = document.createElement("button");
    this.sheetBackButton.className = "pl_sheet__back-button";

    this.sheetBackButton.appendChild(document.createElement("i"));

    if (title) {
      const titleElement = document.createElement("span");
      titleElement.setAttribute("aria-label", typeof title === "string" ? title : "Zurück");
      titleElement.innerHTML = title.toString();
      this.sheetBackButton.appendChild(titleElement);
    } else {
      this.sheetBackButton.setAttribute("aria-label", "Zurück");
    }
  }

  removeBackButton(): void {
    if (this.sheetBackButton && this.sheetHeader.contains(this.sheetBackButton)) {
      this.sheetHeader.removeChild(this.sheetBackButton);
      this.sheetBackButton = undefined;
    }
  }

  setCloseButton(): void {
    this.removeCloseButton();

    this.sheetCloseButton = document.createElement("button");
    this.sheetCloseButton.className = "pl_sheet__close-button";
    this.sheetCloseButton.setAttribute("aria-label", "Schließen");
    this.sheetCloseButton.appendChild(document.createElement("i"));

    this.sheetHeader.appendChild(this.sheetCloseButton);

    this.sheetCloseButton.addEventListener("click", () => {
      this.close(SHEET_CLOSE_TYPE.CLOSED_WITH_X);
    });
  }

  removeCloseButton(): void {
    if (this.sheetCloseButton && this.sheetHeader.contains(this.sheetCloseButton)) {
      this.sheetHeader.removeChild(this.sheetCloseButton);
      this.sheetCloseButton = undefined;
    }
  }

  /**
 *
 *
 *
 *
 */
  setHeader(header: SheetElementContent, updateContentHeight = true): void {
    if (!header) {
      return;
    }

    this.setElementContent(header, this.sheetHeader);

    if (updateContentHeight) {
      this.updateContentMaxHeight();
    }
  }

  /**
 *
 *
 *
 *
 */
  setTitle(title: SheetElementContent, updateContentHeight = true): void {
    if (!title) {
      return;
    }

    this.sheetTitle.innerText = "";
    this.setElementContent(title, this.sheetTitle);

    if (updateContentHeight) {
      this.updateContentMaxHeight();
    }
  }

  /**
 *
 *
 *
 */
  setContent(content: SheetElementContent): void {
    if (!content) {
      return;
    }

    this.setElementContent(content, this.sheetContent);

    this.extractTitleFromContent();

    if (this.settings.convertLayerLinks) {
      this.convertPresentLayerLinksToSheetLinks();
    }
  }

  /**
 *
 *
 *
 *
 */
  setMenuBar(menuBarContent: SheetElementContent, updateContentHeight = true): void {
    if (!menuBarContent) {
      return;
    }

    this.setElementContent(menuBarContent, this.sheetMenuBar);

    this.sheetContainer.appendChild(this.sheetMenuBar);

    this.sheetContainer.classList.remove("pl_sheet__menu-bar-hidden");

    if (updateContentHeight) {
      this.updateContentMaxHeight();
    }
  }

  /**
 *
 *
 *
 */
  hideMenuBar(updateContentHeight = true): void {
    this.sheetContainer.classList.add("pl_sheet__menu-bar-hidden");

    if (updateContentHeight) {
      this.updateContentMaxHeight();
    }
  }

  updateContentMaxHeight(): void {
    const headerHeight = this.sheetHeader.clientHeight;
    const menuBarHeight = this.sheetMenuBar.clientHeight;

    if (!window.matchMedia("only screen and (max-width: 479px)").matches) {
      this.sheetContent.style.maxHeight = `calc(100vh - ${headerHeight}px - ${menuBarHeight}px)`;
      return;
    }

    /*                                                                             */
    const calculatedMaxContentHeight =
      this.sheetContainer.clientHeight - headerHeight - menuBarHeight;

    /*                                                                            */
    const adjustedMaxContentHeight =
      calculatedMaxContentHeight > 24 ? calculatedMaxContentHeight : 24;

    this.sheetContent.style.maxHeight =
      this.settings.initialMobileHeight || this.settings.lockMobileHeight
        ? `${adjustedMaxContentHeight}px`
        : `calc(${Sheet.getSheetMaxHeight()} - ${headerHeight}px - ${menuBarHeight}px`;
  }

  /**
 *
 *
 *
 *
 */
  setContentFromURL(
    url: string,
    callback?: (responseText?: string, responseStatus?: number, error?: Error) => void,
  ): void {
    fetch(url, { redirect: "manual" })
      .then((response) => {
        if (!response.ok) {
          const error = new Error("response is not ok") as Error & { status: number };
          /*                                                                             */
          error.status = response.status || 300;

          throw error;
        }

        response.text().then((text) => {
          this.setContent(text);

          if (typeof callback === "function") {
            callback(text, response.status);
          }
        });
      })
      .catch((error: Error & { status?: number }) => {
        const errorTemplate =
          '<div class="p_message p_message--error pl_sheet--error">' +
          "<b>Entschuldigung!</b> Aufgrund von technischen Problemen kann der Inhalt leider nicht " +
          "geladen werden. Bitte versuche es zu einem späteren Zeitpunkt erneut." +
          "</div>";

        this.setContent(errorTemplate);

        if (typeof callback === "function") {
          callback(errorTemplate, error.status, error);
        }
      });
  }

  registerContentLoadedHandler(): void {
    window.o_global.events<SheetEvents>().on(SHEET_EVENTS.contentLoaded, () => {
      window.setTimeout(() => {
        if (
          (this.settings.initialMobileHeight || this.settings.lockMobileHeight) &&
          window.matchMedia("only screen and (max-width: 479px)").matches
        ) {
          this.updateContainerHeight();
        }
        this.updateContentMaxHeight();
      }, 0);
    });
  }

  updateContainerHeight(): void {
    this.sheetContainer.style.height = this.settings.initialMobileHeight
      ? this.settings.initialMobileHeight
      : `${this.sheetContainer.clientHeight}px`;
  }

  /**
 *
 */
  open(): void {
    if (!this.isOpen) {
      if (this.settings.headerContent) {
        this.setHeader(this.settings.headerContent, false);
      } else {
        this.setTitle(this.settings.title ? this.settings.title : "", false);

        this.sheetHeader.appendChild(this.sheetTitle);

        if (this.settings.showCloseButton) {
          this.setCloseButton();
        }
      }

      /*                         */
      registerTrackingContextHandler(() => {
        if (this.settings.createTrackingContext) {
          createTrackingContext(this.sheetContent.id);
        }
      });

      if (this.settings.menuContent) {
        this.setMenuBar(this.settings.menuContent, false);
      } else {
        this.hideMenuBar(false);
      }

      /*                   */
      this.sheetCurtain.classList.add("pl_sheet-curtain--hidden");
      this.sheetCurtain.addEventListener("click", () => {
        this.close(SHEET_CLOSE_TYPE.CLOSED_WITH_CURTAIN_CLICK);
      });

      this.sheetContainer.appendChild(this.sheetHeader);
      this.sheetContainer.appendChild(this.sheetContent);
      this.sheetContainer.appendChild(this.sheetMenuBar);

      /*                   */
      document.getElementsByTagName("body")[0].appendChild(this.sheetCurtain);
      document.getElementsByTagName("body")[0].appendChild(this.sheetContainer);
      this.isOpen = true;

      /*                                                                                                       */
      this.registerContentLoadedHandler();

      /*            */
      if (this.settings.url) {
        this.setContentFromURL(this.settings.url, (responseText, status, error) => {
          if (!error) {
            window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoaded, { id: this.id });
          } else {
            window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoadingFailed, {
              id: this.id,
              errorCode: status,
              errorText: responseText ?? "",
            });
          }
        });
      } else {
        this.setContent(this.settings.content ?? "");
        window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoaded, { id: this.id });
      }

      /*                                        */
      if (this.settings.preOpenCallback) {
        this.settings.preOpenCallback();
      }

      /*                                              */
      window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.activeSheetChanged, this);

      /*                                                                     */
      /*                                                                                       */
      window.getComputedStyle(this.sheetContainer).opacity;

      this.sheetContainer.classList.remove("pl_sheet--hidden");
      this.sheetContainer.classList.add("pl_sheet--open");

      /*                                                                           */
      /*                                                                                            */
      /*                                                          */
      if ((window as any)?.o_apps?.runningInApp?.()) {
        this.sheetContainer.classList.add("pl_sheet--app");
      }
      this.sheetCurtain.classList.remove("pl_sheet-curtain--hidden");

      if (window.o_global.breakpoint.isBreakpointActive(["s"])) {
        this.gestureHandler.registerGestureEvents();
      }

      const activeElement = document.activeElement as HTMLElement;

      /*                                                                                                    */
      if (activeElement) {
        activeElement.blur();
      }

      window.addEventListener("resize", () => {
        this.updateContentMaxHeight();
      });

      /*                                                                */
      setTimeout(() => {
        /*                                */
        if (this.settings.openCallback) {
          this.settings.openCallback();
        }

        /*                            */
        if (this.settings.trackingObject) {
          submitEvent(this.settings.trackingObject);
        }

        /*              */
        window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.opened, this);
      }, 250);
    }
  }

  /**
 *
 *
 *
 *
 *
 */
  close(
    closeType: SheetCloseTypes = SHEET_CLOSE_TYPE.CLOSED_PROGRAMMATICALLY,
    silent = false,
  ): void {
    if (this.isOpen) {
      if (!silent) {
        /*                                                 */
        window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.activeSheetChanged, undefined);
      }

      this.isOpen = false;

      /*                                         */
      if (this.settings.preCloseCallback) {
        this.settings.preCloseCallback({ closeType });
      }

      this.sheetContainer.classList.remove("pl_sheet--open");
      this.sheetContainer.classList.add("pl_sheet--close");
      this.sheetCurtain.classList.add("pl_sheet-curtain--hidden");

      setTimeout(() => {
        this.sheetContainer.classList.add("pl_sheet--hidden");
        this.sheetContainer.classList.remove("pl_sheet--close");

        if (this.settings.closeCallback) {
          this.settings.closeCallback({ closeType });
        }

        if (this.settings.createTrackingContext) {
          closeTrackingContext();
        }

        this.removeSheetAndCurtain();

        /*               */
        window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.closed, { closeType, id: this.id });
      }, 250);
    }
  }

  /**
 *
 *
 *
 */
  switchSheetContent(initParameters: Record<string, unknown>): void {
    /*                               */
    this.settingsStack.push({ ...this.settings });
    const { createTrackingContext: oldContext } = this.settings;

    /*                                     */
    this.settings = { ...defaultSettings, ...initParameters };

    const { createTrackingContext: newContext } = this.settings;

    /*                                                */
    if (this.settings.showBackButton && !this.sheetHeader.contains(this.sheetBackButton as Node)) {
      this.setBackButton(() => this.revertSheetContent());
    }

    registerTrackingContextHandler(() =>
      handleTrackingContext(this.sheetContent.id, { oldContext, newContext }),
    );

    /*                                   */
    this.sheetContent.classList.add("hide");
    this.contentStack.push(`pl_sheet__content__${this.contentStack.length}`);

    /*                     */
    if (this.settings.contentGreyBackground === true) {
      this.sheetContainer.classList.add("pl_sheet--grey-bg");
    } else {
      this.sheetContainer.classList.remove("pl_sheet--grey-bg");
    }

    /*                                                    */
    this.createEmptySwitchContentContainer();

    /*            */
    if (this.settings.url) {
      this.setContentFromURL(this.settings.url, (responseText, status, error) => {
        if (!error) {
          window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoaded, { id: this.id });
        } else {
          window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoadingFailed, {
            id: this.id,
            errorCode: status,
            errorText: responseText ?? "",
          });
        }
      });
    } else {
      this.setContent(this.settings.content ?? "");
      window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentLoaded, { id: this.id });
    }

    if (this.settings.title) {
      this.setTitle(this.settings.title, false);
    }

    this.clearChildren(this.sheetMenuBar);

    /*                      */
    if (this.settings.menuContent) {
      this.setMenuBar(this.settings.menuContent, false);
    } else {
      this.hideMenuBar(false);
    }

    if (this.settings.url) {
      window.o_global.events<SheetEvents>().on(SHEET_EVENTS.contentLoaded, () => {
        this.updateContentMaxHeight();
      });
    } else {
      this.updateContentMaxHeight();
    }

    /*                    */
    if (this.settings.openCallback) {
      this.settings.openCallback();
    }

    /*                            */
    if (this.settings.trackingObject) {
      submitEvent(this.settings.trackingObject);
    }

    /*                          */
    window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentSwitched, this.sheetContent);
  }

  /**
 *
 *
 *
 */
  createEmptySwitchContentContainer(): void {
    const newContentContainer = document.createElement("div");

    newContentContainer.classList.add("pl_sheet__content");

    /*                                                     */
    newContentContainer.setAttribute("id", `pl_sheet__content__${this.contentStack.length}`);

    /*                                               */
    this.sheetContent = newContentContainer;

    if (this.settings.fullBleedContent || this.settings.contentGreyBackground) {
      this.sheetContent.classList.add("pl_sheet__content--full-bleed");
    }

    /*                                */
    this.sheetContainer.insertBefore(this.sheetContent, this.sheetMenuBar);
  }

  /**
 *
 *
 *
 */
  revertSheetContent(): void {
    if (this.contentStack.length !== 0) {
      if (this.sheetContent) {
        this.sheetContainer.removeChild(this.sheetContent);
      }

      this.sheetContent = document.getElementById(this.contentStack.pop() as string) as HTMLElement;

      this.sheetContent.classList.toggle("hide");

      /*                          */
      const { closeCallback, createTrackingContext: oldContext } = this.settings;

      this.settings = this.settingsStack.pop() as SheetSettings;
      const { createTrackingContext: newContext } = this.settings;

      /*                     */
      if (this.settings.contentGreyBackground === true) {
        this.sheetContainer.classList.add("pl_sheet--grey-bg");
      } else {
        this.sheetContainer.classList.remove("pl_sheet--grey-bg");
      }

      if (!this.settings.showBackButton || this.contentStack.length === 0) {
        this.removeBackButton();
      }

      if (this.settings.title) {
        this.setTitle(this.settings.title, false);
      }

      this.clearChildren(this.sheetMenuBar);

      /*                      */
      if (this.settings.menuContent) {
        this.setMenuBar(this.settings.menuContent, false);
      } else {
        this.hideMenuBar(false);
      }

      this.updateContentMaxHeight();

      handleTrackingContext(this.sheetContent.id, { oldContext, newContext });

      if (closeCallback) {
        closeCallback({ closeType: SHEET_CLOSE_TYPE.CLOSED_PROGRAMMATICALLY });
      }

      window.o_global.events<SheetEvents>().emit(SHEET_EVENTS.contentReverted);
    }
  }

  /**
 *
 */
  /*                                              */
  clearChildren(element: HTMLElement): void {
    if (element.children.length > 0) {
      Array.from(element.children).forEach((childNode) => {
        element.removeChild(childNode);
      });
    }
  }

  /**
 *
 *
 */
  exposeInterface(): SheetAPI {
    return {
      close: () => this.close(),
      open: () => this.open(),
      getSettings: () => this.getSettings(),
      back: () => this.revertSheetContent(),
      getContainer: () => this.getContainer(),
      setBackButton: (callback: EventListener, title: string) =>
        this.setBackButton(callback, title),
      getBackButton: () => this.getBackButton(),
      removeBackButton: () => this.removeBackButton(),
      setCloseButton: () => this.setCloseButton(),
      getCloseButton: () => this.getCloseButton(),
      removeCloseButton: () => this.removeCloseButton(),
      setHeader: (header: string | HTMLElement | HTMLElement[]) => this.setHeader(header),
      getHeader: () => this.getHeader(),
      setTitle: (title: string) => this.setTitle(title),
      getTitle: () => this.getTitle(),
      setContent: (content: string | HTMLElement | HTMLElement[]) => this.setContent(content),
      getContent: () => this.getContent(),
      setMenuBar: (menuBar: string | HTMLElement | HTMLElement[]) => this.setMenuBar(menuBar),
      getMenuBar: () => this.getMenuBar(),
      hideMenuBar: () => this.hideMenuBar(),
      switchSheetContent: (settings: Record<string, unknown>) => this.switchSheetContent(settings),
    };
  }

  static getSheetMaxHeight(): string {
    /*                                                          */
    return (window as any)?.o_apps?.runningInApp() ? "var(--sheet-max-height)" : "80vh";
  }

  static getActiveSheet = getActiveSheet;

  /*                                                                             */
  static init(): void {
    sendCustomError("assets.sheet", {});
  }

  back = this.revertSheetContent;
}
