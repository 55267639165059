import type { Sheet } from "./Sheet";
import { SheetEvents, SHEET_CLOSE_TYPE, SHEET_EVENTS } from "./sheetEvents";
import { SheetAPI } from "./sheetTypes";

/**
 *
 */
const settingsMap: Record<string, string> = {
  id: "data-sheet-id",
  title: "data-sheet-title",
  headerContent: "data-sheet-header-content",
  content: "data-sheet-content",
  url: "data-sheet-url",
  base64url: "data-sheet-ub64e",
  menuContent: "data-sheet-menu-content",
  menuBarVertical: "data-sheet-menu-bar-vertical",
  openCallback: "data-sheet-open-callback",
  closeCallback: "data-sheet-close-callback",
  preOpenCallback: "data-sheet-pre-open-callback",
  preCloseCallback: "data-sheet-pre-close-callback",
  trackingObject: "data-sheet-tracking-object",
  showBackButton: "data-sheet-show-back-button",
  showCloseButton: "data-sheet-show-close-button",
  initialMobileHeight: "data-sheet-initial-mobile-height",
  lockMobileHeight: "data-sheet-lock-mobile-height",
  fullBleedContent: "data-sheet-full-bleed-content",
  contentGreyBackground: "data-sheet-content-grey-background",
  convertLayerLinks: "data-sheet-convert-layer-links",
  createTrackingContext: "data-sheet-create-tracking-context",
};

let activeSheet: Sheet | undefined; /*                     */

export function getScrollbarWidth(): number {
  /*                           */
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; /*                          */
  outer.style.setProperty("-ms-overflow-style", "scrollbar"); /*                    */
  document.getElementsByTagName("body")[0].appendChild(outer);

  /*                                                     */
  const inner = document.createElement("div");
  outer.appendChild(inner);

  /*                                                                        */
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  /*                                       */
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

/**
 *
 *
 *
 */
export function enableScrollPrevention(): void {
  if (!window.o_global.breakpoint.isSmall()) {
    document.documentElement.style.marginRight = `${getScrollbarWidth()}px`;
  }

  document.documentElement.style.overflow = "hidden";
}

/**
 *
 *
 *
 */
export function disableScrollPrevention(): void {
  document.documentElement.style.removeProperty("overflow");

  if (!window.o_global.breakpoint.isSmall()) {
    document.documentElement.style.removeProperty("margin-right");
  }
}

/**
 *
 *
 *
 */
export function setActiveSheet(sheet?: Sheet): void {
  if (sheet) {
    if (activeSheet !== undefined) {
      activeSheet.close(SHEET_CLOSE_TYPE.CLOSED_PROGRAMMATICALLY, true);
    }

    activeSheet = sheet;
    enableScrollPrevention();
  } else {
    activeSheet = undefined;
    disableScrollPrevention();
  }
}

window.o_global.events<SheetEvents>().on(SHEET_EVENTS.activeSheetChanged, (sheet?: Sheet) => {
  setActiveSheet(sheet);
});

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function parseDataAttributes(element: HTMLElement): Record<string, unknown> {
  const settings = {} as Record<string, unknown>;

  Object.keys(settingsMap).forEach((key) => {
    const attribute = element.getAttribute(settingsMap[key]);

    if (attribute) {
      let parsedAttribute: unknown = null;

      /*                            */
      switch (attribute) {
        case "false":
          parsedAttribute = false;
          break;
        case "true":
          parsedAttribute = true;
          break;
        case "null":
          parsedAttribute = null;
          break;
        default:
          parsedAttribute = attribute;
      }

      if (key === "statusCallbacks") {
        try {
          const jsonParsed = JSON.parse(attribute);

          Object.keys(jsonParsed).forEach((statusCode) => {
            jsonParsed[statusCode] = window.o_util.core.convertStringToFunction(
              jsonParsed[statusCode],
              window,
            );
          });

          parsedAttribute = jsonParsed;
        } catch (e) {
          throw new Error("Could not parse data-sheet-status-callbacks. Not valid json");
        }
      } else if (key.indexOf("Callback") > -1) {
        /*                                   */
        const func = new Function(`return ${attribute}`)();
        parsedAttribute = func;
      }

      settings[key] = parsedAttribute;
    }
  });

  return settings;
}

/**
 *
 *
 *
 *
 *
 */
export function initSheet(event: MouseEvent, NewSheet: typeof Sheet): void {
  let trigger = event.target as HTMLElement | null;
  const isValidTrigger = window.o_util.misc.isValidMouseButton(event, 1);

  if (trigger && !trigger?.classList.contains("js_openInPaliSheet")) {
    trigger = window.o_util.dom.getParentByClassName(trigger, "js_openInPaliSheet");
  }

  if (!!trigger && !!isValidTrigger) {
    const attributes = parseDataAttributes(trigger);

    if (attributes.base64url) {
      attributes.url = window.atob(attributes.base64url as never);
    }

    if (attributes.altUrl) {
      attributes.url = attributes.altUrl;

      /*                                                         */
      if (isValidTrigger && (event.metaKey || event.ctrlKey)) {
        return;
      }
    }

    if (window.o_util.dom.hasClassInParents(trigger, "pl_sheet")) {
      /*              */
      activeSheet?.switchSheetContent(attributes);
    } else {
      /*         */
      const sheet = new NewSheet(attributes);

      if (!trigger.hasAttribute("data-sheet-id")) {
        trigger.setAttribute("data-sheet-id", sheet.id);
      }

      sheet.open();
    }

    window.o_util.event.stop(event);
  }
}

/**
 *
 *
 *
 */
export function getActiveSheet(): SheetAPI | undefined {
  if (activeSheet) {
    return activeSheet.exposeInterface();
  }
  return undefined;
}
