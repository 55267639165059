import { submitEvent } from "@otto-ec/tracking-bct";
import { isOutOfBounds } from "../../common/src/utils";
import { TooltipEvents, TOOLTIP_EVENTS } from "./tooltipEvents";

type TooltipSettings = {
  text: string;
  openOnlyOnce: string;
  position: string;
  id: string | null;
  additionalContainerClasses?: string;
  trackingObject?: string;
};

type TooltipSettingsKeys = keyof TooltipSettings;

function getDimensions(element: HTMLElement): {
  elementDimensions: DOMRect;
  relativeOffsetLeft: number;
  relativeOffsetTop: number;
} {
  return {
    elementDimensions: element.getBoundingClientRect(),
    relativeOffsetLeft:
      (window.pageXOffset || document.documentElement.scrollLeft) -
      (document.documentElement.clientLeft || 0),
    relativeOffsetTop:
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0),
  };
}

function parseDataAttributes(element: HTMLElement): TooltipSettings {
  const settingsMap: Record<TooltipSettingsKeys, string> = {
    text: "data-tooltip-text", /*                             */
    openOnlyOnce: "data-tooltip-open-only-once", /*                                                     */
    position: "data-tooltip-position", /*                                                           */
    id: "data-tooltip-id", /*                                                            */
    additionalContainerClasses: "data-tooltip-container-class", /*                                             */
    trackingObject: "data-tooltip-trackingobject", /*                                          */
  };

  const parsedTooltipSettings: TooltipSettings = {
    text: "",
    openOnlyOnce: "false",
    position: "top",
    id: null,
    additionalContainerClasses: "",
    trackingObject: undefined,
  };

  const keys = Object.keys(settingsMap) as TooltipSettingsKeys[];

  keys.forEach((key) => {
    const attribute = element.getAttribute(settingsMap[key]) || "";

    parsedTooltipSettings[key] = attribute;
  });

  return parsedTooltipSettings;
}

function createTooltipElement(): {
  container: HTMLDivElement;
  textContent: HTMLDivElement;
  closeIcon: SVGSVGElement;
  anchor: HTMLDivElement;
} {
  /*                  */
  const container = document.createElement("div");
  const textContent = document.createElement("div");
  const closeIcon = document.createElementNS("http:/*                           */
  const anchor = document.createElement("div");

  /*             */
  container.classList.add("pl_tooltip--top", "pl_tooltip--hidden");
  textContent.classList.add("pl_copy100");
  closeIcon.classList.add("pl_icon");
  closeIcon.setAttribute("role", "img");
  closeIcon.innerHTML = `<use href="/assets-static/icons/pl_icon_close.svg#pl_icon_close" xlink:href="/assets-static/icons/pl_icon_close.svg#pl_icon_close"></use>`;

  anchor.classList.add("pl_tooltip__anchor-bottom");

  /*           */
  container.append(textContent, closeIcon, anchor);

  return {
    container,
    textContent,
    closeIcon,
    anchor,
  };
}

let clickHandlerInternal: (event: Event) => void;

let resizeCallback: () => void;

let resizeTimeout: number | undefined;

export const close = (
  tooltipElementContainer: HTMLElement,
  tooltipHost: HTMLElement,
  openOnlyOnce = false,
): void => {
  tooltipElementContainer.parentNode?.removeChild(tooltipElementContainer);

  if (!openOnlyOnce) {
    tooltipHost.addEventListener("click", clickHandlerInternal);
  }

  window.removeEventListener("resize", resizeCallback, false);

  resizeTimeout = undefined;

  window.o_global
    .events<TooltipEvents>()
    .emit(TOOLTIP_EVENTS.CLOSED, { id: tooltipElementContainer.id });
};

export const open = (tooltipHostElement: HTMLElement): void => {
  tooltipHostElement.removeEventListener("click", clickHandlerInternal);

  const settings = parseDataAttributes(tooltipHostElement);

  const tooltipElement = createTooltipElement();

  const applyTooltipSettings = (): void => {
    tooltipElement.closeIcon.addEventListener("click", () =>
      close(tooltipElement.container, tooltipHostElement, settings.openOnlyOnce === "true"),
    );

    if (settings.id) {
      tooltipElement.container.id = settings.id;
    } else {
      const guid = window.o_util.misc.guid();
      tooltipElement.container.id = guid;
      tooltipHostElement.setAttribute("data-tooltip-id", guid);
    }

    tooltipElement.textContent.innerText = settings.text;

    if (settings.additionalContainerClasses) {
      tooltipElement.container.classList.add(settings.additionalContainerClasses);
    }
  };

  const flipTooltipElementTopToBottom = (): void => {
    tooltipElement.container.classList.remove("pl_tooltip--top");
    tooltipElement.anchor.classList.remove("pl_tooltip__anchor-bottom");

    tooltipElement.container.classList.add("pl_tooltip--bottom");
    tooltipElement.anchor.classList.add("pl_tooltip__anchor-top");

    if (tooltipElement.container.classList.contains("pl_tooltip--bottom")) {
      tooltipElement.container.style.top = `${
        tooltipElement.container.offsetTop +
        tooltipHostElement.getBoundingClientRect().height +
        12 +
        12
      }px`;
    }
  };

  const flipTooltipElementRightToLeft = (): void => {
    const hostDimensions: DOMRect = tooltipHostElement.getBoundingClientRect();

    tooltipElement.anchor.style.removeProperty("left");
    tooltipElement.anchor.style.right = "12px";

    /*                                                                                                                                           */
    tooltipElement.container.style.minWidth = "max-content";

    /*                                                                                           */
    const containerComputedMaxWidth = Number.parseInt(
      window.getComputedStyle(tooltipElement.container).maxWidth.replace("px", ""),
      10,
    );
    /*                                                                                                                       */
    const actualTooltipElementWidth =
      tooltipElement.container.getBoundingClientRect().width > containerComputedMaxWidth
        ? containerComputedMaxWidth
        : tooltipElement.container.getBoundingClientRect().width;
    /*                                                                                          */
    tooltipElement.container.style.removeProperty("min-width");

    tooltipElement.container.style.left = `${
      hostDimensions.left + Math.round(hostDimensions.width / 2) - actualTooltipElementWidth + 20 /*                                                         */
    }px`;
  };

  const setTooltipPosition = (
    {
      container,
      anchor,
      tooltipHost,
    }: {
      container: HTMLElement;
      anchor: HTMLElement;
      tooltipHost: HTMLElement;
    },
    appendToDom = false,
  ): void => {
    const {
      elementDimensions: hostDimensions,
      relativeOffsetLeft,
      relativeOffsetTop,
    } = getDimensions(tooltipHost);
    /*                                                    */
    /*                                        */
    const isTooltipHostInsideSheet = tooltipHost.closest(".pl_sheet--open");

    const openSheetContent = document.querySelector<HTMLElement>(".pl_sheet__content") as
      | HTMLElement
      | undefined;

    const gridContainer = document.querySelector(".gridContainer") as HTMLElement | undefined;

    if (window.matchMedia("only screen and (max-width: 447px)").matches) {
      anchor.style.left = `${hostDimensions.left - 16 + hostDimensions.width / 2}px`;
      container.style.left = `${8 + relativeOffsetLeft}px`;
    } else {
      anchor.style.left = "12px";
      container.style.left = `${
        (isTooltipHostInsideSheet
          ? tooltipHostElement.offsetLeft
          : hostDimensions.left + relativeOffsetLeft) +
        Math.round(hostDimensions.width / 2) -
        20 /*                                                          */
      }px`;
    }

    container.style.top = `${
      (isTooltipHostInsideSheet
        ? tooltipHostElement.offsetTop
        : hostDimensions.top + relativeOffsetTop) - 12 /*                                    */
    }px`;

    if (appendToDom) {
      if (isTooltipHostInsideSheet) {
        openSheetContent?.appendChild(container);
      } else {
        if (openSheetContent) {
          container.style.setProperty("z-index", "9000");
        }

        window.document.body.appendChild(container);
      }
    }

    if (
      isOutOfBounds(container, openSheetContent ?? gridContainer).top ||
      settings.position === "bottom"
    ) {
      flipTooltipElementTopToBottom();
    }

    if (isOutOfBounds(container, openSheetContent ?? gridContainer).right) {
      flipTooltipElementRightToLeft();
    }

    if (appendToDom) {
      container.classList.remove("pl_tooltip--hidden");
    }
  };

  const updateAllTooltipPositions = (): void => {
    const hostElements = document.querySelectorAll<HTMLElement>(".js_openTooltip");

    hostElements.forEach((tooltipHost) => {
      const { tooltipId } = tooltipHost.dataset;
      const container = tooltipId ? document.getElementById(tooltipId) : undefined;
      const anchor = container
        ? (container.children[container.children.length - 1] as HTMLElement)
        : undefined;

      if (container && anchor) {
        setTooltipPosition({ container, anchor, tooltipHost });
      }
    });
  };

  applyTooltipSettings();
  setTooltipPosition(
    {
      container: tooltipElement.container,
      anchor: tooltipElement.anchor,
      tooltipHost: tooltipHostElement,
    },
    true,
  );

  resizeCallback = (): void => {
    if (resizeTimeout) {
      window.cancelAnimationFrame(resizeTimeout);
    }

    resizeTimeout = window.requestAnimationFrame(function () {
      updateAllTooltipPositions();
    });
  };

  window.addEventListener("resize", resizeCallback, false);

  window.o_global
    .events<TooltipEvents>()
    .emit(TOOLTIP_EVENTS.OPENED, { id: tooltipElement.container.id });

  if (settings.trackingObject) {
    submitEvent(JSON.parse(settings.trackingObject));
  }
};

clickHandlerInternal = (event: Event): void => {
  const triggerElement = event?.target as HTMLElement;

  const tooltipHostElement =
    (window.o_util.dom.getParentByClassName(
      event.target as HTMLElement,
      "js_openTooltip",
    ) as HTMLElement) || triggerElement;

  open(tooltipHostElement);
};

export const clickHandler = clickHandlerInternal;
