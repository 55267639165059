/**
 *
 *
 *
 *
 *
 */

window.o_global.eventQBus.on("assets.switch.init", () => {
  const switchElements = document.querySelectorAll(`.js-pl_switch`);
  [].forEach.call(switchElements, (el) => {
    el.addEventListener("change", (e) => {
      window.o_global.eventQBus.emit("assets.switch.changed", e);
    });
    el.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.switch.init");
});
