import { dom } from "@otto-ec/global-resources/dom";

export const clickHandler = (event: Event): void => {
  const triggerElement = event?.target as HTMLElement;

  const textExpander = dom.getParentByClassName(triggerElement, "pl_text-expander");
  let customEvent;
  let isOpen;

  if (textExpander) {
    textExpander.classList.toggle("pl_text-expander--expanded");
    isOpen = !!textExpander.classList.contains("pl_text-expander--expanded");

    customEvent = new CustomEvent("pl_text-expanderChange", {
      detail: {
        isOpen,
      },
      bubbles: true,
    });

    textExpander.dispatchEvent(customEvent);
    window.o_global.eventQBus.emit("assets.textExpander.toggled", {
      isOpen,
      target: customEvent.target,
    });
  }
};
