import { submitEvent } from "@otto-ec/tracking-bct";

/*                        */
window.o_global.popup =
  window.o_global.popup ||
  (function () {
    /**
 *
 *
 *
 *
 */
    function getCenterPosition(width, height) {
      const x = (window.screen.width - width) / 2;
      const y = (window.screen.height - height) / 2;

      return `,left=${x},top=${y},screenX=${x},screenY=${y}`;
    }

    /**
 *
 *
 *
 *
 */
    function openPopup(url, name, width, height) {
      let centerPosition;
      let options;
      let newWindow;

      if (!name || name === "") {
        name = "popup";
      }

      if (!parseInt(width, 10)) {
        width = 800;
      }

      if (!parseInt(height, 10)) {
        height = 575;
      }

      centerPosition = getCenterPosition(width, height);
      options = `width=${width},height=${height},toolbar=no,location=no,directories=no,scrollbars=yes,status=no,menubar=no,resizable=no${centerPosition}`;

      newWindow = window.open(url, name, options);
      if (newWindow) {
        newWindow.focus();
      }

      /*                                                                   */
      if (!!window.AS.toggles && !!window.AS.toggles.isPreview) {
        return;
      }

      if (url.indexOf("http") === 0 && url.indexOf(document.location.hostname) === -1) {
        submitEvent({
          ot_Aussprung: url,
        });
      }
    }

    /**
 *
 */
    function openHandler(e) {
      const trigger = window.o_util.event.getTarget(e);
      /*                                                        */
      const target = trigger.classList.contains("js_openInPopup")
        ? trigger
        : window.o_util.dom.getParentByClassName(trigger, "js_openInPopup");
      const url = target.getAttribute("href") || target.getAttribute("data-popup-href") || "";

      /*                                       */
      if (window.o_util.cookie.exists("app")) {
        return;
      }

      openPopup(
        url,
        target.getAttribute("data-popup-name"),
        target.getAttribute("data-popup-width"),
        target.getAttribute("data-popup-height"),
      );

      e.preventDefault();
    }

    /**
 *
 */
    function init() {
      window.o_util.event.delegate(document, "click", ".js_openInPopup", openHandler);
    }

    window.o_global.eventLoader.onReady(10, init);

    return {
      open: openPopup,
    };
  })();

window.o_global.pali.initCloseWindow =
  window.o_global.pali.initCloseWindow ||
  function () {
    window.o_util.event.delegate(document, "click", ".js_closeWindow", function () {
      window.close();
    });
  };

window.o_global.pali.initCloseWindow();
