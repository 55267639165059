import type { Sheet } from "./Sheet";
import { SHEET_CLOSE_TYPE } from "./sheetEvents";
import { browserTransforms } from "./sheetTypes";

export class SheetGestureHandler {
  sheet: Sheet;

  currentSheetOffsetY = 0;

  initialTouchPos: { x: number; y: number } | null = null;

  lastTouchPos: { x: number; y: number } | null = null;

  rafPending = false;

  constructor(options: { sheet: Sheet }) {
    this.sheet = options.sheet;
  }

  /**
 *
 *
 *
 *
 */
  registerGestureEvents(): void {
    /*                                     */
    const { sheetHeader } = this.sheet;
    if (window.PointerEvent) {
      /*                          */
      sheetHeader.addEventListener("pointerdown", this.handleGestureStart, true);
      sheetHeader.addEventListener("pointerup", this.handleGestureEnd, true);
      sheetHeader.addEventListener("pointercancel", this.handleGestureEnd, true);
    } else {
      /*                                                   */
      /*                 */
      sheetHeader.addEventListener("touchstart", this.handleGestureStart, true);
      sheetHeader.addEventListener("touchend", this.handleGestureEnd, true);
      sheetHeader.addEventListener("touchcancel", this.handleGestureEnd, true);

      /*                 */
      sheetHeader.addEventListener("mousedown", this.handleGestureStart, true);
    }
  }

  /**
 *
 *
 *
 */
  /*                                              */
  getGesturePointFromEvent(event: PointerEvent | TouchEvent | MouseEvent): {
    x: number;
    y: number;
  } {
    const point = { x: 0, y: 0 };

    if (event instanceof TouchEvent && event.targetTouches) {
      point.x = event.targetTouches[0].clientX;
      point.y = event.targetTouches[0].clientY;
    } else {
      point.x = (event as MouseEvent | PointerEvent).clientX;
      point.y = (event as MouseEvent | PointerEvent).clientY;
    }

    return point;
  }

  /**
 *
 *
 *
 *
 *
 */
  handleGestureStart = (event: MouseEvent | PointerEvent | TouchEvent): void => {
    event.preventDefault();

    if (event instanceof TouchEvent && event.touches && event.touches.length > 1) {
      return;
    }

    const target = event.target as HTMLElement | undefined;

    /*                                                           */
    if (window.PointerEvent) {
      this.sheet.sheetHeader.addEventListener("pointermove", this.handleGestureMove, true);
      target?.setPointerCapture((event as PointerEvent).pointerId);
    } else {
      /*                                                                          */
      document.addEventListener("touchmove", this.handleGestureMove, true);
      document.addEventListener("mousemove", this.handleGestureMove, true);
      document.addEventListener("mouseup", this.handleGestureEnd, true);
    }

    this.initialTouchPos = this.getGesturePointFromEvent(event);

    this.sheet.sheetContainer.style.transition = ""; /*                                       */
  };

  /**
 *
 *
 *
 */
  handleGestureMove = (event: PointerEvent | TouchEvent | MouseEvent): void => {
    event.preventDefault();

    if (!this.initialTouchPos) {
      return;
    }

    this.lastTouchPos = this.getGesturePointFromEvent(event);

    if (this.rafPending) {
      return;
    }

    this.rafPending = true;

    window.requestAnimationFrame(this.executeVerticalDrag.bind(this));
  };

  /**
 *
 *
 */
  handleGestureEnd = (event: PointerEvent | TouchEvent | MouseEvent): void => {
    event.preventDefault();

    if (event instanceof TouchEvent && event.touches && event.touches.length > 0) {
      return;
    }

    this.rafPending = false;

    /*                     */
    if (window.PointerEvent && event instanceof PointerEvent) {
      const target = event.target as HTMLElement | undefined;

      target?.releasePointerCapture(event.pointerId);
      this.sheet.sheetHeader.removeEventListener("pointermove", this.handleGestureMove, true);
    } else {
      /*                     */
      document.removeEventListener("touchmove", this.handleGestureMove, true);
      document.removeEventListener("mousemove", this.handleGestureMove, true);
      document.removeEventListener("mouseup", this.handleGestureEnd, true);
    }

    this.initialTouchPos = null;

    if (this.currentSheetOffsetY / this.sheet.sheetContainer.clientHeight >= 0.4) {
      /*                                                */
      window.requestAnimationFrame(this.initiateClosingAnmiation.bind(this));
      this.sheet.close(SHEET_CLOSE_TYPE.CLOSED_WITH_DRAG);
    } else {
      /*                                 */
      window.requestAnimationFrame(this.returnToInitialPos.bind(this));
    }
  };

  /**
 *
 */
  executeVerticalDrag(): void {
    if (!this.rafPending) {
      return;
    }

    const differenceInY =
      this.lastTouchPos && this.initialTouchPos ? this.lastTouchPos.y - this.initialTouchPos.y : 0;

    if (differenceInY > 0) {
      this.currentSheetOffsetY = differenceInY;

      const transformStyle = `translateY(${differenceInY}px)`;
      const { sheetContainer } = this.sheet;
      sheetContainer.style.setProperty("-moz-transform", transformStyle);
      sheetContainer.style.setProperty("-ms-transform", transformStyle);
      sheetContainer.style.transform = transformStyle;
    }

    this.rafPending = false;
  }

  /**
 *
 */
  returnToInitialPos(): void {
    /*                        */
    this.sheet.sheetContainer.style.transition = "";
    this.setSheetContainerTransformStyleCrossBrowser("");

    this.currentSheetOffsetY = 0;

    this.rafPending = false;
  }

  /**
 *
 */
  initiateClosingAnmiation(): void {
    this.sheet.sheetContainer.style.transition = "transform 300ms ease-in";
    const transformStyle = `translateY(${this.sheet.sheetContainer.clientHeight}px)`;
    this.setSheetContainerTransformStyleCrossBrowser(transformStyle);

    this.rafPending = false;
  }

  /**
 *
 *
 */
  setSheetContainerTransformStyleCrossBrowser(transformStyle: string): void {
    browserTransforms.forEach((key) => {
      this.sheet.sheetContainer.style.setProperty(key, transformStyle);
    });
  }
}
