/*                                  */
/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
import {
  checkValue,
  analyzeTarget,
  AnalyzeTargetReturn,
  jumpToPrevious,
  addLeadingZero,
  preventDecimals,
} from "./utils";

/**
 *
 *
 *
 */

window.o_global.eventQBus.on("assets.formular.init", (selector = "") => {
  const inputPSelector = `${selector} .js-p_input input`;
  const inputPlSelector = `${selector} .js-pl_input input`;
  const inputElements = document.querySelectorAll(`${inputPSelector},${inputPlSelector}`);

  const eventMap: Record<string, (...params: any[]) => void> = {
    input: ({
      currentLength,
      maxLength,
      target,
      autoTab,
      inputType,
      intOnly,
      data,
      value,
    }: AnalyzeTargetReturn & { target: HTMLInputElement; data: string }) => {
      /*                        */
      /*                                               */
      if (inputType === "number") {
        /*                                               */
        /*                                      */
        if (preventDecimals(data, intOnly)) {
          /*                                */
          if (value) {
            /*                                              */
            target.value = "";
            target.value = value;
          }
        }
      }

      if (currentLength >= maxLength) {
        /*                                  */
        /*                              */
        const allowed = target.value.substring(0, maxLength);
        /*                          */
        const rest = target.value.replace(allowed, "");
        /*                                  */
        target.value = allowed;

        if (autoTab) {
          /*              */
          const { form } = target;
          const index = Array.prototype.indexOf.call(form, target);
          const next = form?.elements[index + 1] as HTMLInputElement;

          /*                                       */
          if (next && !next.value) {
            /*                                                  */
            next.focus();
            /*                                             */
            /*                                       */
            if (rest) {
              next.value = rest;
              /*                                                                          */
              /*                */
              eventMap.input({
                ...analyzeTarget(next),
                target: next,
                data: "",
              });
            }
          }
        }
      }
    },

    paste: ({ event, inputType, intOnly }: AnalyzeTargetReturn & { event: ClipboardEvent }) => {
      if (inputType === "number") {
        /*                                     */

        const val = checkValue(event.clipboardData?.getData("text") || "", intOnly);
        if (!val) {
          /*                                              */
          /*                                             */
          event.preventDefault();
        }
      }
    },

    blur: ({ maxLength, target, inputType, intOnly, value }) => {
      /*                        */
      if (inputType === "number") {
        const resultValue = checkValue(value, intOnly);
        /*                                                            */
        const length = resultValue ? resultValue.toString().length : 0;

        if (
          addLeadingZero({
            resultValue,
            length,
            maxLength,
          })
        ) {
          /*                                           */
          target.value = `0${resultValue}`;
        } else {
          target.value = resultValue;
        }
      }
    },

    keydown: ({ key, currentLength, target, event, autoTab }) => {
      if (
        jumpToPrevious({
          key,
          autoTab,
          currentLength,
        })
      ) {
        const { form } = target;
        const index = Array.prototype.indexOf.call(form, target);
        const prev = form.elements[index - 1];
        if (prev) {
          prev.focus();
          /*                                            */
          /*                                                   */
          event.preventDefault();
        }
      }
    },

    beforeinput: ({ event, intOnly }) => {
      const numberOnlyRegex = /^[0-9]*$/;
      const decimalsOnlyRegex = /^[0-9.,]*$/;

      if (event.target.type === "number") {
        if (intOnly) {
          if (event.data === null || numberOnlyRegex.test(event.data)) {
            return;
          }
          event.preventDefault();
        } else if (event.data === null || decimalsOnlyRegex.test(event.data)) {
          return;
        }
        event.preventDefault();
      }
    },
  };

  /**
 *
 *
 */
  const handler = (event: KeyboardEvent | InputEvent | MouseEvent): void => {
    const { keyCode, key, metaKey, data } = event as Partial<
      KeyboardEvent & InputEvent & MouseEvent
    >;

    const { target } = event;

    const { autoTab, currentLength, inputType, maxLength, intOnly, value } = analyzeTarget(
      target as HTMLInputElement
    );

    eventMap[event.type]({
      data,
      key,
      keyCode,
      metaKey,
      currentLength,
      maxLength,
      event,
      target,
      value,
      autoTab,
      intOnly,
      inputType,
    });
  };

  inputElements.forEach((element) => {
    Object.keys(eventMap).forEach((eventName) => {
      element.addEventListener(eventName, handler as () => void);
    });
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.formular.init", "body");
});
