/**
 *
 *
 */
export interface OutOfBounds {
  /*                                            */
  top: boolean;
  /*                                             */
  left: boolean;
  /*                                               */
  bottom: boolean;
  /*                                              */
  right: boolean;
  /*                                                 */
  any: boolean;
  /*                                         */
  all: boolean;
}

/**
 *
 *
 *
 */
function packOutOfBounds(results: Omit<OutOfBounds, "any" | "all">): OutOfBounds {
  const r = Object.values(results);
  return { ...results, any: r.some(Boolean), all: r.every(Boolean) };
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function isOutOfBounds(
  element: HTMLElement,
  container?: HTMLElement,
  w = window /*                    */
): OutOfBounds {
  /*                            */
  if (container) {
    return packOutOfBounds({
      top: element.offsetTop < 0,
      bottom: element.offsetTop + element.offsetHeight < container.offsetHeight,
      left: element.offsetLeft < 0,
      right: element.offsetLeft + element.offsetWidth >= container.offsetWidth,
    });
  }

  /*                                                        */
  const boundingRect = element.getBoundingClientRect();
  return packOutOfBounds({
    top: boundingRect.top < 0,
    bottom: boundingRect.bottom > (w.innerHeight || document.documentElement.clientHeight),
    left: boundingRect.left < 0,
    right: boundingRect.right >= (w.innerWidth || document.documentElement.clientWidth),
  });
}
