window.o_global.pali.expander = {
  expanderClassName: "pl_expander",
  clickEventHandler(event) {
    const { target } = event;
    const expanderRootElement = target.closest(
      `.${window.o_global.pali.expander.expanderClassName}`
    );

    if (expanderRootElement) {
      expanderRootElement.classList.toggle(`pl_expander--expanded`);
      const isOpen = expanderRootElement.classList.contains(`pl_expander--expanded`);
      window.o_global.pali.expander.dispatchExpanderEvent(isOpen, target);
    }
  },
  clickEventHandlerNoFade(event) {
    const { target } = event;
    const toggleClass = `pl_expander--no-fade__extended`;

    target.classList.toggle(toggleClass);
    const isOpen = target.classList.contains(toggleClass);
    window.o_global.pali.expander.dispatchExpanderEvent(isOpen, target);
  },
  registerClickEventListeners(selector = "") {
    const plExpanderToggleElements = document.querySelectorAll(
      ` ${selector} .js_pl_expander__toggle`
    );

    [].forEach.call(plExpanderToggleElements, (header) => {
      header.addEventListener("click", window.o_global.pali.expander.clickEventHandler);
    });

    const plExpanderNoFadeToggleElements = document.querySelectorAll(
      ` ${selector} .js_pl_expander--no-fade__toggle`
    );

    [].forEach.call(plExpanderNoFadeToggleElements, (header) => {
      header.addEventListener("click", window.o_global.pali.expander.clickEventHandlerNoFade);
    });
  },
  dispatchExpanderEvent(state, target) {
    const customEvent = new CustomEvent(`pl_expanderChange`, {
      detail: {
        isOpen: state,
      },
      bubbles: true,
    });

    target.dispatchEvent(customEvent);
    window.o_global.eventQBus.emit("assets.expander.toggled", customEvent);
  },
};

window.o_global.eventQBus.on(
  "assets.expander.init",
  window.o_global.pali.expander.registerClickEventListeners
);

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.eventQBus.emit("assets.expander.init", "body");
});
